import { useAuth0 } from '@auth0/auth0-react'
import { useLogout } from '@hooks/useLogout'
import { useUserStore } from '@stores/userStore'
import { getAccessToken } from '@utils/auth0'
import { useCallback, useEffect } from 'react'

type SessionGuardProps = {
  children?: React.ReactNode
}

// The SessionGuard sets up handlers for checking and detecting when an
//  Auth0 session has expired - automatically routing to login if it has.
export const Auth0SessionGuard = ({ children }: SessionGuardProps) => {
  const { isAuthenticated } = useAuth0()
  const shouldUseAuth0 = useUserStore(userStore => !userStore.apiKey)
  const setUserIdWithAccessToken = useUserStore(userStore => userStore.setUserIdWithAccessToken)
  const logout = useLogout()

  const refreshAccessToken = useCallback(async () => {
    try {
      const token = await getAccessToken()
      setUserIdWithAccessToken(token)
    } catch (e) {
      logout({ redirectPath: window.location.href })
    }
  }, [logout, setUserIdWithAccessToken])

  const handleTabFocusChange = useCallback(async () => {
    const tabFocused = document.visibilityState === 'visible'
    if (tabFocused && isAuthenticated) {
      await refreshAccessToken()
    }
  }, [isAuthenticated, refreshAccessToken])

  useEffect(() => {
    // TODO: remove this check when legacy API token auth is removed
    if (!shouldUseAuth0) {
      return
    }
    window.addEventListener('visibilitychange', handleTabFocusChange)
    return () => {
      window.removeEventListener('visibilitychange', handleTabFocusChange)
    }
  }, [handleTabFocusChange, shouldUseAuth0])

  useEffect(() => {
    handleTabFocusChange()
  }, [handleTabFocusChange])

  return children
}
