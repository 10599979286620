/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.0.0
 */

export type EdgeType = (typeof EdgeType)[keyof typeof EdgeType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EdgeType = {
  company_sends_to_summarized: 'company_sends_to_summarized',
  directed_by: 'directed_by',
  facility_sends_to_summarized: 'facility_sends_to_summarized',
  operated_by: 'operated_by',
  owned_by: 'owned_by',
  risk_entity_related_to: 'risk_entity_related_to',
  shareholder_of: 'shareholder_of',
} as const
