import { useLoggedIn } from '@hooks/useLoggedIn'
import { useUserStore } from '@stores/userStore'
import { useRouter } from 'next/router'
import { PropsWithChildren, useEffect } from 'react'
import { authenticateApiKey } from '@lib/login'
import { useAuth0 } from '@auth0/auth0-react'
import { AUTH0_AUDIENCE, AUTH0_SCOPE } from '@utils/auth0'
import { logDebug } from '@utils/logger'
import { useLogout } from '@hooks/useLogout'

const ALLOWED_ROUTES = ['/login', '/logout']

type LoginGuardProps = PropsWithChildren<Record<string, unknown>>

export const LoginGuard = ({ children }: LoginGuardProps) => {
  const router = useRouter()
  const { _hydrated: isHydrated, apiKey, setUserIdWithAccessToken } = useUserStore()
  const isLoggedIn = useLoggedIn()
  const logout = useLogout()
  const currentPathname = router.pathname

  const { isAuthenticated, isLoading, getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()

  const shouldUseAuth0 = useUserStore(userStore => !userStore.apiKey)

  useEffect(() => {
    let isStale = false
    if (!isHydrated) {
      return
    }

    if (shouldUseAuth0) {
      if (isLoading) {
        return
      }
      if (!isAuthenticated && !ALLOWED_ROUTES.includes(currentPathname)) {
        logout({ redirectPath: window.location.href })
      } else {
        if (isAuthenticated) {
          getAccessTokenSilently({
            audience: AUTH0_AUDIENCE,
            scope: AUTH0_SCOPE,
          })
            .then(token => {
              if (isStale) return
              setUserIdWithAccessToken(token)
            })
            .catch(error => {
              if (isStale) {
                logDebug({ error, description: 'Error occurred on stale request to get Auth0 access token.' })
                return
              }
              // If getting the token silently above fails due to needing user consent, get the token with the consent popup.
              // This should only appear in local development and not in production (due to running on localhost).
              // Read more: https://auth0.com/docs/get-started/applications/confidential-and-public-applications/user-consent-and-third-party-applications
              getAccessTokenWithPopup({
                audience: AUTH0_AUDIENCE,
                scope: AUTH0_SCOPE,
              })
                .then(token => {
                  setUserIdWithAccessToken(token)
                })
                .catch(_error => {
                  // ignore errors
                })
            })
        }
      }
      // TODO - (LEGACY_AUTH) - When removing legacy auth we should be able to remove the if statement (specifically the else block) from this code.
    } else {
      const loginAsync = async (apiKey: string) => {
        await authenticateApiKey(apiKey)
      }

      if (typeof window !== 'undefined' && isHydrated && !isLoggedIn && !ALLOWED_ROUTES.includes(currentPathname)) {
        if (apiKey) {
          loginAsync(apiKey)
        } else {
          logout({ redirectPath: window.location.href })
        }
      }
    }
    return () => {
      isStale = true
    }
  }, [
    apiKey,
    currentPathname,
    getAccessTokenSilently,
    getAccessTokenWithPopup,
    isAuthenticated,
    isHydrated,
    isLoading,
    isLoggedIn,
    logout,
    setUserIdWithAccessToken,
    shouldUseAuth0,
  ])

  return (
    <>{((isHydrated && (isLoggedIn || isAuthenticated)) || ALLOWED_ROUTES.includes(currentPathname)) && children}</>
  )
}
