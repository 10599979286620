import { PropsWithChildren, createContext, useEffect, useState } from 'react'

import { createPathSearchStore } from '@stores/pathSearchStore'
import { createCompaniesListSearchStore } from '@stores/companiesListSearchStore'
import { createTabularSearchInputStore } from '@stores/tabularSearchInputStore'
import { CompaniesListSearchFormStoreState, TabularSearchInputState, PathSearchState } from '@type/Search'

type SearchContextState = {
  pathSearchStore: ReturnType<typeof createPathSearchStore>
  companiesListSearchStore: ReturnType<typeof createCompaniesListSearchStore>
  tabularSearchStore: ReturnType<typeof createTabularSearchInputStore>
}

export const SearchContext = createContext<SearchContextState | undefined>(undefined)

export type SearchProviderProps = PropsWithChildren<{
  initialTabularSearchInputState?: Partial<TabularSearchInputState>
  initialPathSearchState?: Partial<PathSearchState>
  initialCompaniesListSearchStore?: Partial<CompaniesListSearchFormStoreState>
}>

// This is wrapped around the entire app to maintain search form state when a user navigates between any page.
// Session storage or other persistence pattern is not used to avoid maintaining state through a refresh / new tab
export const SearchProvider = ({
  children,
  initialTabularSearchInputState,
  initialPathSearchState,
  initialCompaniesListSearchStore,
}: SearchProviderProps) => {
  const [stores, setStores] = useState<SearchContextState>()
  useEffect(() => {
    setStores(
      current =>
        current ?? {
          pathSearchStore: createPathSearchStore(initialPathSearchState ?? {}),
          companiesListSearchStore: createCompaniesListSearchStore(initialCompaniesListSearchStore ?? {}),
          tabularSearchStore: createTabularSearchInputStore(initialTabularSearchInputState ?? {}),
        },
    )
  }, [initialTabularSearchInputState, initialPathSearchState, initialCompaniesListSearchStore])

  return stores ? <SearchContext.Provider value={stores}>{children}</SearchContext.Provider> : null
}
