import { JobState } from '@stores/jobStore'
import { Job } from '@type/Job'
import { logDebug } from './logger'
import { JobStatusReport } from '@type/API/generated/models'

export const isJobFinished = (job: Job | undefined): boolean =>
  !!job &&
  (job.statusReport.status === 'COMPLETE' ||
    job.statusReport.status === 'CANCELLED' ||
    job.statusReport.status === 'FAILED')

const DEFAULT_CHECK_WAIT_MS = 1000 * 10

export const checkJobStatus = (
  jobId: string | undefined,
  updateStatusReport: (status: JobStatusReport) => void,
  fetchStatusReport: (jobId: string) => Promise<JobStatusReport>,
  waitTimeMS?: number,
  timer?: ReturnType<typeof setTimeout>,
) => {
  if (!jobId) return

  const recheckStatus = () => {
    const newTimer: ReturnType<typeof setTimeout> = setTimeout(
      () => checkJobStatus(jobId, updateStatusReport, fetchStatusReport, waitTimeMS, newTimer),
      waitTimeMS || DEFAULT_CHECK_WAIT_MS,
    )
  }

  fetchStatusReport(jobId)
    .then(statusReport => {
      if (!statusReport.status) {
        recheckStatus()
        return
      }
      updateStatusReport(statusReport)
      switch (statusReport.status) {
        case 'PENDING':
        case 'RUNNING':
          recheckStatus()
          break
      }
    })
    .catch(error => {
      logDebug(error)
      recheckStatus()
    })
    .finally(() => timer && clearTimeout(timer))
}

export const updateJobStatusInState = (id: string, statusReport: Job['statusReport'], state: JobState) => {
  const jobToUpdate = state.jobs.get(id)
  if (!jobToUpdate) return state
  const newJob = { ...jobToUpdate, statusReport }
  return { ...state, jobs: new Map(state.jobs).set(jobToUpdate.id, newJob) }
}

export const getJobStatusTooltipText = (job: Job) => {
  switch (job.statusReport.status) {
    case 'PENDING':
    case 'RUNNING':
      return `${job.jobType} of ${job.description} is in progress`
    case 'COMPLETE':
    case 'CANCELLED':
    case 'FAILED':
      return `Take action on the current ${job.jobType?.toLowerCase()} before starting a new one`
    default:
      return undefined
  }
}
