import { IdToken } from '@auth0/auth0-spa-js'
import { AccessTokenPayload } from '@utils/auth0'
import { logDebug } from '@utils/logger'
import jwt_decode from 'jwt-decode'
import { create } from 'zustand'
import { persist, devtools } from 'zustand/middleware'
import { storage } from './storage'
import { getUserInfo } from '@type/API/generated/internal-v3-admin/internal-v3-admin'
import { GetUserInfo200, UserProfile } from '@type/API/generated/models'
import { updateUserProfile } from '@type/API/generated/internal-v3-user-profile/internal-v3-user-profile'
import { pick } from 'lodash'

type UserState = {
  apiKey: string
  legacyUserId: string
  idTokenClaims: IdToken
  userId: string
  userProfile: null | UserProfile
  _loadUserInfoPromise: null | Promise<unknown>
  _hydrated: boolean
  saveApiKey: (key: string) => void
  loadUserInfo: () => void
  clearApiKey: () => void
  setUserIdWithAccessToken: (token?: string) => void
  clearUserId: () => void
  ingestIdTokenClaims: (claims?: IdToken) => void
  setKeycloakCredentials: (data?: GetUserInfo200) => void
  setHasUnauthorizedError: (hasUnauthorizedError: boolean) => void
  setUserProfile: (userProfile: UserProfile) => void
  hasUnauthorizedError: boolean
}

// TODO: once Keycloak is implemented for all spokes, this store is no longer needed. user data should be accessible via Context.Provider in `_app.tsx`

// Admin authorization helper fn
/**
 * @deprecated use `isUserAuthorizedForResources` instead
 */
export const isAdmin = (roles: string | string[] | undefined): boolean => {
  if (typeof roles === 'string') {
    return roles === 'admin' || roles.includes('/admin')
  }
  if (Array.isArray(roles)) {
    return roles.some(role => role === 'admin' || role.includes('/admin'))
  }
  return false
}

const persistedApiKey = typeof window !== 'undefined' && localStorage.getItem('persistedapitoken')
const unsetIdTokenClaims: UserState['idTokenClaims'] = { __raw: 'unset' }

const persistStorageKey = 'user'
export const manuallyClearPersistedUserStore = () => storage.removeItem(persistStorageKey)
export const userStoreKeysToPersist: (keyof UserState)[] = ['apiKey', 'legacyUserId']

export const useUserStore = create<UserState>()(
  devtools(
    persist(
      (set, get) => ({
        apiKey: persistedApiKey || '',
        legacyUserId: persistedApiKey ? persistedApiKey.slice(0, 8) : '',
        idTokenClaims: unsetIdTokenClaims,
        userId: '',
        userProfile: null as null | UserProfile,
        _loadUserInfoPromise: null,
        _hydrated: Boolean(false),
        hasUnauthorizedError: false,
        saveApiKey: apiKey => {
          const legacyUserId = apiKey.length > 8 ? apiKey.slice(0, 8) : ''
          // Need to set apiKey here so the fetch for getUserInfo is authenticated
          set({ apiKey, legacyUserId })
        },
        loadUserInfo: () => {
          const doLoadUserInfo = async () => {
            try {
              const _loadUserInfoPromise = getUserInfo()
              set({ _loadUserInfoPromise })
              const response = await _loadUserInfoPromise
              if ('user_profile' in response) {
                set({ userProfile: response.user_profile })
              }
            } catch (error) {
              logDebug(`Error fetching user info: ${error}`)
            } finally {
              // Wipe this out so other callers can load user info
              set({ _loadUserInfoPromise: null })
            }
          }
          // NOTE(idr): This store retrieves user profile data using getUserInfo() in this function,
          // but it also sets user profile data as a side effect of setIdTokenClaims(), after which
          // it calls loadUserInfoIntoStore() another time to make sure the latest user profile
          // data is loaded. We don't want a call that's already in flight to clobber that one,
          // so we use this promise to sequence calls to getUserInfo() one at a time.
          const existingPromise = Promise.resolve(get()._loadUserInfoPromise)
          set({ _loadUserInfoPromise: existingPromise.then(doLoadUserInfo) })
        },
        setUserProfile: (profile: UserProfile) => {
          set({ userProfile: profile })
        },
        clearApiKey: () => {
          set({ apiKey: '', legacyUserId: '' })
        },
        setUserIdWithAccessToken: accessToken => {
          if (typeof accessToken !== 'string' || accessToken.length === 0) {
            set({ userId: '' })
            return
          }
          try {
            const { sub: userId }: AccessTokenPayload = jwt_decode(accessToken)
            logDebug(`Parsed user ID: ${userId} from access token`)
            set({ userId })
          } catch (error) {
            logDebug("Couldn't decode access token", undefined, error)
            set({ userId: '' })
          }
        },
        ingestIdTokenClaims: (idTokenClaims?: IdToken): void => {
          set({ idTokenClaims })
          updateUserProfile(
            {
              given_name: idTokenClaims?.given_name,
              family_name: idTokenClaims?.family_name,
              email: idTokenClaims?.email,
            },
            { only_overwrite_null_values: true },
          ).then(() => get().loadUserInfo())
        },
        setKeycloakCredentials: data => {
          set({ userId: data?.user_id })
        },
        clearUserId: () => set({ userId: '' }),
        setHasUnauthorizedError: (hasUnauthorizedError: boolean) => set({ hasUnauthorizedError }),
      }),
      {
        name: persistStorageKey,
        version: 1,
        getStorage: () => storage,
        partialize: state => pick(state, userStoreKeysToPersist),
        migrate: (persistedState, version) => {
          if (version === 0) {
            // NOTE (kyle.dunn): the `partialize` method only applies when saving the state to storage, not when loading it.
            //                   This ensures we only load the keys we want to persist, even if they exist in storage.
            return pick(persistedState, userStoreKeysToPersist) as UserState
          }

          return persistedState as UserState
        },
        onRehydrateStorage: () => () => {
          useUserStore.setState({
            _hydrated: true,
          })
        },
      },
    ),
    { name: 'User' }, // provides a name for the store in redux devtools
  ),
)
