/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.0.0
 */
import { NodeType } from './nodeType'
import { EdgeType } from './edgeType'

export type ExplorationEntityMetadataExplorationEntityType =
  (typeof ExplorationEntityMetadataExplorationEntityType)[keyof typeof ExplorationEntityMetadataExplorationEntityType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExplorationEntityMetadataExplorationEntityType = { ...NodeType, ...EdgeType } as const
