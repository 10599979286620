import { sprinkles } from '@styles/sprinkles.css'

export const unauthorizedUserRegex = /^user .+ is not part of the .+ organization$/

export const UnauthorizedUserMessage = () => (
  <div className={sprinkles({ paddingTop: 64, paddingX: 16 })}>
    <p className={sprinkles({ fontSize: 13 })}>
      You are not authorised to access this product. If you believe this is incorrect, please recheck your credentials
      or contact an Altana administrator for further assistance (
      <a target="_blank" rel="noopener noreferrer" href="mailto:customersuccess@altana.ai">
        customersuccess@altana.ai
      </a>
      ).
    </p>
  </div>
)
