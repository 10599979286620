import { useAuth0 } from '@auth0/auth0-react'
import { setLoginRedirectUrl } from '@lib/login'
import { manuallyClearPersistedUserStore, useUserStore } from '@stores/userStore'
import { useCallback } from 'react'
import { unauthorizedUserRegex } from '@components/UnauthorizedUserMessage'
import { isFeatureEnabled } from '@components/Feature'

// This function can be called from anywhere - it does not depend
//   on being in a hook or component
export const navigateToLogout = ({ redirectPath }: { redirectPath?: string } = {}) => {
  if (redirectPath) {
    setLoginRedirectUrl(redirectPath)
  }
  window.location.assign('/logout')
}

export const useLogout = () => {
  const { clearUserId, clearApiKey, setHasUnauthorizedError } = useUserStore()
  const { isAuthenticated: isAuthenticatedWithAuth0 } = useAuth0()
  const { logout: logoutAuth0, error } = useAuth0()

  return useCallback(
    async ({ redirectPath }: { redirectPath?: string } = {}) => {
      clearUserId()
      clearApiKey()

      // TODO: Replace IndexDB (which is async) with Local Storage (sync) for persisting UserStore.
      // -- Since UserStore is persisted async, there is no guarantee that our calls to `clearApiKey` will actually be persisted before navigating to login.
      // -- If the apiKey is not cleared from IndexDB, and that key is expired, we will get an infinite loop between login/logout.
      await manuallyClearPersistedUserStore()

      localStorage.removeItem('persistedapitoken')
      if (redirectPath) {
        setLoginRedirectUrl(redirectPath)
      }

      const hasUnauthorizedError = !!(error?.message && unauthorizedUserRegex.test(error.message))
      setHasUnauthorizedError(hasUnauthorizedError)
      if (isAuthenticatedWithAuth0) {
        logoutAuth0({
          returnTo: `${window.location.origin}/login`,
        })
      } else if (isFeatureEnabled('keycloak_auth')) {
        // The `/logout` route is intercepted by Keycloak, when enabled, so this will not load the Next `/logout` page.
        window.location.assign('/logout')
      } else {
        window.location.assign('/login')
      }
    },
    [clearUserId, clearApiKey, logoutAuth0, isAuthenticatedWithAuth0, error, setHasUnauthorizedError],
  )
}
