/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.0.0
 */

export type LionCompanySearchRequestV2Collection =
  (typeof LionCompanySearchRequestV2Collection)[keyof typeof LionCompanySearchRequestV2Collection]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LionCompanySearchRequestV2Collection = {
  company: 'company',
} as const
