/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.0.0
 */

export type TransactionCoreSearchFields = (typeof TransactionCoreSearchFields)[keyof typeof TransactionCoreSearchFields]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransactionCoreSearchFields = {
  bill_of_lading_number: 'bill_of_lading_number',
  container_number: 'container_number',
  country_of_destination_iso_alpha2: 'country_of_destination_iso_alpha2',
  country_of_origin_iso_alpha2: 'country_of_origin_iso_alpha2',
  data_source_country: 'data_source_country',
  destination_company_canon_id: 'destination_company_canon_id',
  goods_description: 'goods_description',
  hs_code: 'hs_code',
  imo_number: 'imo_number',
  logistics_party_name: 'logistics_party_name',
  logistics_party_scac: 'logistics_party_scac',
  nvocc_scac: 'nvocc_scac',
  shipment_date: 'shipment_date',
  port_of_destination_unlocode: 'port_of_destination_unlocode',
  port_of_lading_unlocode: 'port_of_lading_unlocode',
  port_of_origin_unlocode: 'port_of_origin_unlocode',
  port_of_unlading_unlocode: 'port_of_unlading_unlocode',
  receiver_name: 'receiver_name',
  sender_name: 'sender_name',
  source_company_canon_id: 'source_company_canon_id',
  source_record_id: 'source_record_id',
  transaction_id: 'transaction_id',
  vocc_scac: 'vocc_scac',
} as const
