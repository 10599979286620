import { logDebug } from './logger'
import { v4 as uuid } from 'uuid'

export type WindowWithPendo = typeof window & {
  pendo: {
    initialize: (config: { visitor: { id: string }; account?: { id: string } }) => void
  }
}

export const PendoVisitorIdKey = 'pendo_visitorId'

export const getPendoVisitorId = () => {
  const id = window.localStorage.getItem('pendo_visitorId')
  if (!id) {
    const pendoVisitorId = uuid()
    window.localStorage.setItem(PendoVisitorIdKey, pendoVisitorId)
    return pendoVisitorId
  }
  return id
}

export const tryInitializePendo = () => {
  if ('pendo' in window) {
    logDebug('Pendo installed. Initializing...')
    ;(window as WindowWithPendo).pendo.initialize({
      visitor: {
        id: getPendoVisitorId(),
      },
    })
  } else {
    logDebug('Pendo not installed')
  }
}
