import { AxiosRequestConfig } from 'axios'
import { authenticatedFetcher } from '@lib/fetcher'

// This is a customize instance of the axios http client
// for use in the client functions generated by Orval located in ./type/API/generated/

export const customInstance = <T>(config: AxiosRequestConfig): Promise<T> => {
  const resource = `/atlas${config.url}`

  return authenticatedFetcher({
    ...config,
    resource,
  })
}
