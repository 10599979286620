/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.0.0
 */

export type CoreSearchCollection = (typeof CoreSearchCollection)[keyof typeof CoreSearchCollection]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CoreSearchCollection = {
  companies: 'companies',
  facilities: 'facilities',
  procurements: 'procurements',
  transactions: 'transactions',
} as const
