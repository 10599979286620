import { Auth0ProviderOptions } from '@auth0/auth0-react'
import env from '@beam-australia/react-env'
import createAuth0Client, { Auth0Client, Auth0ClientOptions, CacheLocation } from '@auth0/auth0-spa-js'

export type AccessTokenPayload = {
  sub: string // Subject - user id
  iss: string // Issuer - Who created this token
  aud: [string] // Audience - The APIs this token is valid for
  exp: number // Expiration time stamp
  iat: number // Issued at timestamp
  azp: string // Authorized party - The client ID this token was issued to
  scope: string // The individual permissions this token has
  org_id: string // Organization ID for this user
}

const LOGIN_REDIRECT_URL = typeof window !== 'undefined' ? window.origin : env('LOGIN_REDIRECT_URL') || ''

export const AUTH0_SCOPE = env('AUTH0_SCOPE') || ''

export const AUTH0_AUDIENCE = env('AUTH0_AUDIENCE') || ''

export const AUTH0_DOMAIN = env('AUTH0_DOMAIN') || ''

export const AUTH0_CLIENT_ID = env('AUTH0_CLIENT_ID') || ''

export const AUTH0_ORGANIZATION_ID = env('AUTH0_ORGANIZATION_ID') || ''

const CACHE_LOCATION: CacheLocation = 'localstorage'

export const Auth0BaseConfig = {
  domain: AUTH0_DOMAIN,
  scope: AUTH0_SCOPE,
  audience: AUTH0_AUDIENCE,
  cacheLocation: CACHE_LOCATION,
  useRefreshTokens: true,
}

export const Auth0ClientConfig: Auth0ClientOptions = {
  ...Auth0BaseConfig,
  client_id: AUTH0_CLIENT_ID,
  organization: AUTH0_ORGANIZATION_ID,
}

export const Auth0ProviderConfig: Auth0ProviderOptions = {
  ...Auth0BaseConfig,
  clientId: AUTH0_CLIENT_ID,
  redirectUri: LOGIN_REDIRECT_URL,
  organization: AUTH0_ORGANIZATION_ID,
}

// Used for setting up clients and retrieving access tokens
const fetchToken = async (client: Auth0Client, ignoreCache: boolean) =>
  client.getTokenSilently({
    audience: AUTH0_AUDIENCE,
    scope: AUTH0_SCOPE,
    ignoreCache,
  })

let auth0ClientPromise: Promise<Auth0Client> | null = null
export const getClient = () => {
  if (auth0ClientPromise) return auth0ClientPromise

  auth0ClientPromise = createAuth0Client(Auth0ClientConfig).catch(e => {
    clearClient()
    throw e
  })
  return auth0ClientPromise
}
export const clearClient = () => {
  auth0ClientPromise = null
}

export const getAccessToken = async (ignoreCache = false) => {
  const client = await getClient()
  const token = await fetchToken(client, ignoreCache)
  return token
}

export const getUser = async () => {
  const client = await getClient()
  return client.getUser()
}

export const isAuthenticated = async () => {
  const client = await getClient()
  return await client.isAuthenticated()
}

export const getIdTokenClaims = async () => {
  const client = await getClient()
  return client.getIdTokenClaims()
}
