/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.0.0
 */

export type TransactionNodeCollectionType =
  (typeof TransactionNodeCollectionType)[keyof typeof TransactionNodeCollectionType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransactionNodeCollectionType = {
  transaction: 'transaction',
} as const
