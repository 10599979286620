import { fetcher, LEGACY_AUTH_HEADER_NAME } from './fetcher'

export const loginRedirectKey = 'loginRedirectUrl'
export const setLoginRedirectUrl = (url: string) => sessionStorage.setItem(loginRedirectKey, url)
export const consumeLoginRedirectUrl = () => {
  const url = sessionStorage.getItem(loginRedirectKey)
  sessionStorage.removeItem(loginRedirectKey)
  return url
}

// This method is used as a fake login and it tests if the API key provided is valid
export const authenticateApiKey = async (apiKey: string) => {
  await fetcher(apiKey, LEGACY_AUTH_HEADER_NAME)({ resource: '/atlas/public/v3/types' })
  return true
}
