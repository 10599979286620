/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.0.0
 */
import { CompanyCoreSearchFields } from './companyCoreSearchFields'
import { FacilityCoreSearchFields } from './facilityCoreSearchFields'
import { TransactionCoreSearchFields } from './transactionCoreSearchFields'
import { ProcurementCoreSearchFields } from './procurementCoreSearchFields'

export type CoreFieldSearchFormMetadataName =
  (typeof CoreFieldSearchFormMetadataName)[keyof typeof CoreFieldSearchFormMetadataName]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CoreFieldSearchFormMetadataName = {
  ...CompanyCoreSearchFields,
  ...FacilityCoreSearchFields,
  ...TransactionCoreSearchFields,
  ...ProcurementCoreSearchFields,
} as const
