import { useEffect } from 'react'
import type { AppProps } from 'next/app'
import Script from 'next/script'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import 'maplibre-gl/dist/maplibre-gl.css'
import '@styles/globals.css'
import env from '@beam-australia/react-env'
import { LoginGuard } from '@components/LoginGuard'
import { useRouter } from 'next/router'
import { useUser } from '@hooks/useUser'
import { LicenseManager } from 'ag-grid-enterprise'
import { Auth0Provider } from '@auth0/auth0-react'
import { Auth0ProviderConfig, getUser, getIdTokenClaims } from '@utils/auth0'
import { useUserStore } from '@stores/userStore'
import { initMetrics, logMetric, setMetricsUser } from '@utils/metrics'
import { NextPageWithLayout } from '@layouts/NextPageWithLayout'
import { ToastProvider } from '@components/ui/Toast/Toast'
import Head from 'next/head'
import { IconContext, IconProps } from '@phosphor-icons/react'
import { isFeatureEnabled } from '@components/Feature'
import { UserProvider } from '@utils/UserProvider'
import { tryInitializePendo } from '@utils/pendo'
import { GlobalFont } from '@styles/GlobalFont'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { SearchProvider } from '@components/SearchContext'
import { TooltipProvider } from '@components/ui/Tooltip'
import { Auth0SessionGuard } from '@components/Auth0SessionGuard'
import { useLoadUserInfo } from '@hooks/useLoadUserInfo'

initMetrics()

LicenseManager.setLicenseKey(env('AG_GRID_LICENSE_KEY'))

const DEFAULT_ICON_STYLE: IconProps = { color: 'currentColor', size: '1.5rem', weight: 'light' }

type AuthProviderProps = {
  children?: React.ReactNode
}

const queryClient = new QueryClient()

const AuthProvider = ({ children }: AuthProviderProps) => {
  // TODO - (LEGACY_AUTH) - The Auth0Provider can always get rendered after removing leagcy auth
  const shouldUseAuth0 = useUserStore(userStore => !userStore.apiKey)
  return shouldUseAuth0 ? <Auth0Provider {...Auth0ProviderConfig}>{children}</Auth0Provider> : <>{children}</>
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

const isPendoEnabled = isFeatureEnabled('pendo_guides')

const MyApp = ({ Component, pageProps }: AppPropsWithLayout) => {
  const { userId } = useUser()
  const apiKey = useUserStore(state => state.apiKey)
  const setIdTokenClaims = useUserStore(state => state.ingestIdTokenClaims)

  useLoadUserInfo()

  useEffect(() => {
    if (isPendoEnabled && (apiKey || userId)) {
      tryInitializePendo()
    }

    if (!apiKey && userId) {
      getUser().then(auth0User => {
        const auth0UserAttributes = auth0User
          ? {
              name: auth0User.name,
              email: auth0User.email,
              roles: auth0User['https://altana.ai/roles'],
              isInternalAltanaUser: Boolean(auth0User.email?.toLowerCase().endsWith('@altana.ai')).toString(),
            }
          : {}
        setMetricsUser({ id: userId, ...auth0UserAttributes })
      })
      getIdTokenClaims().then(claims => {
        setIdTokenClaims(claims)
      })
    } else {
      setMetricsUser(null)
    }
  }, [setIdTokenClaims, apiKey, userId])

  const router = useRouter()
  useEffect(() => {
    const handleRouteChange = (url: string) => {
      if (router.isReady) {
        logMetric('page_view', { page_location: url })
      }
    }
    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events, router.isReady])

  const getLayout = Component.getLayout ?? (page => page)

  return (
    <>
      <Head>
        <title>Altana</title>
      </Head>
      <Script id="html-to-image" src="/js/html-to-image.js" strategy="afterInteractive" />
      <GlobalFont />
      <AuthProvider>
        <Auth0SessionGuard>
          <IconContext.Provider value={DEFAULT_ICON_STYLE}>
            <ToastProvider>
              <TooltipProvider>
                <QueryClientProvider client={queryClient}>
                  <LoginGuard>
                    <SearchProvider>{getLayout(<Component {...pageProps} />)}</SearchProvider>
                  </LoginGuard>
                  <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
                </QueryClientProvider>
              </TooltipProvider>
            </ToastProvider>
          </IconContext.Provider>
        </Auth0SessionGuard>
      </AuthProvider>
    </>
  )
}

export const KeycloakApp = ({ Component, pageProps }: AppPropsWithLayout) => {
  const router = useRouter()
  const getLayout = Component.getLayout ?? (page => page)

  if (isPendoEnabled) {
    tryInitializePendo()
  }

  useLoadUserInfo()

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      if (router.isReady) {
        logMetric('page_view', { page_location: url })
      }
    }
    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events, router.isReady])

  return (
    <>
      <Head>
        <title>Altana</title>
      </Head>
      <Script id="html-to-image" src="/js/html-to-image.js" strategy="afterInteractive" />
      <GlobalFont />
      <IconContext.Provider value={DEFAULT_ICON_STYLE}>
        <ToastProvider>
          <TooltipProvider>
            <QueryClientProvider client={queryClient}>
              <UserProvider>
                <SearchProvider>{getLayout(<Component {...pageProps} />)}</SearchProvider>
              </UserProvider>
              <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
            </QueryClientProvider>
          </TooltipProvider>
        </ToastProvider>
      </IconContext.Provider>
    </>
  )
}

export default isFeatureEnabled('keycloak_auth') ? KeycloakApp : MyApp
