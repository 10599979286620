/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.0.0
 */

export type CompanySearchRequestCollection =
  (typeof CompanySearchRequestCollection)[keyof typeof CompanySearchRequestCollection]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanySearchRequestCollection = {
  company: 'company',
} as const
