import { CompaniesListSearchFormState, CompaniesListSearchFormStoreState } from '@type/Search'
import { createStore } from 'zustand'

export const createCompaniesListSearchStore = (initialState: CompaniesListSearchFormStoreState) => {
  const defaults = {
    companies: '',
  } satisfies CompaniesListSearchFormStoreState

  return createStore<CompaniesListSearchFormState>()(set => ({
    ...defaults,
    ...initialState,
    updateCompaniesList: (companies?: string) => set({ companies }),
    resetState: () => set({ ...defaults }),
  }))
}
