import {
  Boat,
  Calendar,
  CalendarBlank,
  Database,
  Factory,
  File,
  Flag,
  GlobeHemisphereEast,
  Hash,
  Icon,
  IdentificationCard,
  ListDashes,
  MapPin,
  Package,
  Scales,
  TextAa,
  Truck,
  WarningDiamond,
} from '@phosphor-icons/react'
import { UserSearchPreferencesV2 } from '@type/API/generated/models'
import {
  coreFieldDefaultValues,
  DateRange,
  extensionFieldDefaultValues,
  TabularSearchInputState,
  TabularSearchActiveFields,
  TabularSearchFormValue,
} from '@type/Search'
import {
  CoreFieldSearchFormMetadata,
  CoreFieldSearchFormMetadataName,
  ExtensionFieldSearchFormMetadata,
  SearchFormDefaultsResponse,
  SearchFormInputType,
} from '@type/graph-relay/search/generated/models'

export const parseInitialTabularSearch = (
  formDefaults: SearchFormDefaultsResponse,
  userPreferences: UserSearchPreferencesV2 | undefined,
) => {
  if (userPreferences) {
    return parsePreferredTabularSearch(formDefaults, userPreferences)
  }
  return parseDefaultTabularSearch(formDefaults)
}

const parseDefaultTabularSearch = (formDefaults: SearchFormDefaultsResponse) => {
  const activeFieldsByType: TabularSearchInputState['activeFieldsByType'] = {}
  const fieldOrderByType: TabularSearchInputState['fieldOrderByType'] = {}
  const formStateByType: TabularSearchInputState['formStateByType'] = {}
  const includeOptionsByType: TabularSearchInputState['includeOptionsByType'] = {}

  formDefaults.search_types.forEach(searchType => {
    const activeFields = activeFieldsByType[searchType.name] ?? new Set()
    const formState = formStateByType[searchType.name] ?? {}

    searchType.core_fields?.forEach(field => {
      if (!field.hidden) {
        activeFields.add(field.name)
      }

      formState[field.name] = getCoreFieldDefaultValue(field.name)
    })

    searchType.extension_fields?.forEach(field => {
      if (!field.hidden) {
        activeFields.add(field.name)
      }

      formState[field.name] = getExtensionFieldDefaultValue(field.input_type)
    })

    const fieldOrder = convertActiveFieldstoFieldOrder(activeFields)

    // Add this search type to state if it has any fields
    if (Object.keys(formState).length) {
      activeFieldsByType[searchType.name] = activeFields
      fieldOrderByType[searchType.name] = fieldOrder
      formStateByType[searchType.name] = formState
      includeOptionsByType[searchType.name] = { ...searchType.include_options }
    }
  })

  return {
    activeFieldsByType,
    fieldOrderByType,
    formStateByType,
    includeOptionsByType,
    initialSearchName: Object.keys(formStateByType)[0],
  }
}

const parsePreferredTabularSearch = (
  formDefaults: SearchFormDefaultsResponse,
  userPreferences: UserSearchPreferencesV2,
) => {
  // Set up all basic form and field data before applying user preferences
  const { formStateByType, includeOptionsByType, activeFieldsByType, fieldOrderByType } =
    parseDefaultTabularSearch(formDefaults)

  userPreferences.search_type_preferences
    ?.filter(preference => !!formStateByType?.[preference.search_type])
    .forEach(preference => {
      const activeFields: TabularSearchActiveFields = new Set()
      const formState = formStateByType[preference.search_type]
      if (!formState) return

      preference.fields.forEach(fieldName => {
        // Allow preferred field if it exists in the form data (was found in form defaults)
        if (Object.hasOwn(formState, fieldName)) {
          activeFields.add(fieldName)
        }
      })

      // Override default include options if available
      Object.entries(preference.include_options ?? {}).forEach(([name, value]) => {
        const existingOptions = includeOptionsByType[preference.search_type]
        if (existingOptions?.[name] !== undefined) {
          existingOptions[name] = {
            ...existingOptions[name],
            value,
          }
        }
      })

      const fieldOrder = convertActiveFieldstoFieldOrder(activeFields)
      activeFieldsByType[preference.search_type] = activeFields
      fieldOrderByType[preference.search_type] = fieldOrder
    })

  return {
    activeFieldsByType,
    fieldOrderByType,
    formStateByType,
    includeOptionsByType,
    initialSearchName: userPreferences.last_viewed_search_type ?? formDefaults.search_types[0].name,
  }
}

export const getCoreFieldDefaultValue = (name: CoreFieldSearchFormMetadataName): TabularSearchFormValue => {
  const value = coreFieldDefaultValues[name]
  if (value === null) return null
  if (Array.isArray(value)) return [...value] as typeof value
  if (typeof value === 'object') return { ...value }
  return value
}

export const getExtensionFieldDefaultValue = (inputType: SearchFormInputType): TabularSearchFormValue => {
  const value = extensionFieldDefaultValues[inputType]
  if (value === null) return null
  if (Array.isArray(value)) return [...value]
  if (typeof value === 'object') return { ...value }
  return value
}

export const isCoreFieldMetadata = (
  field: CoreFieldSearchFormMetadata | ExtensionFieldSearchFormMetadata,
): field is CoreFieldSearchFormMetadata => field.name in CoreFieldSearchFormMetadataName
export const isCoreFieldName = (name: string): name is CoreFieldSearchFormMetadataName =>
  name in CoreFieldSearchFormMetadataName
export const isFormValueDateRange = (value: TabularSearchFormValue): value is DateRange =>
  value !== null &&
  typeof value === 'object' &&
  'start_date' in value &&
  'end_date' in value &&
  (value.start_date === null || value.start_date instanceof Date) &&
  (value.end_date === null || value.end_date instanceof Date)

export const coreFieldIcons = {
  awardee_name: TextAa,
  awardee_organization_canon_id: Hash,
  bill_of_lading_number: File,
  bill_of_lading_numbers: File,
  bvd_id: Hash,
  company_canon_id: Hash,
  company_name: TextAa,
  container_number: Hash,
  container_numbers: Hash,
  contract_end_date: CalendarBlank,
  contract_start_date: CalendarBlank,
  contracting_entity_country_iso2: TextAa,
  contracting_entity_name: TextAa,
  contracting_entity_organization_canon_id: Hash,
  country_of_destination_iso_alpha2: GlobeHemisphereEast,
  country_of_origin_iso_alpha2: GlobeHemisphereEast,
  country_of_registration_iso2: GlobeHemisphereEast,
  data_source_country: Database,
  date_of_award: CalendarBlank,
  derived_legal_status: Scales,
  destination_company_canon_id: Hash,
  event_type: ListDashes,
  facility_canon_id: Hash,
  geo_location: MapPin,
  goods_description: Package,
  goods_descriptions_received_tokens: Package,
  goods_descriptions_sent_tokens: Package,
  hs_code: Package,
  hs_codes_traded: Package,
  imo_number: Hash,
  imo_numbers: Hash,
  nace_core_code4: Factory,
  number_of_shipments: Truck,
  operates_in: GlobeHemisphereEast,
  port_of_destination_unlocode: Boat,
  port_of_lading_unlocode: Boat,
  port_of_origin_unlocode: Boat,
  port_of_unlading_unlocode: Boat,
  procurement_event_id: Hash,
  receiver_name: TextAa,
  receives_from: GlobeHemisphereEast,
  risk_exposures: WarningDiamond,
  sender_name: TextAa,
  sends_to: GlobeHemisphereEast,
  shipment_date: CalendarBlank,
  source_company_canon_id: Hash,
  source_record_id: IdentificationCard,
  tax_id: Hash,
  tax_ids: Hash,
  tender_description: TextAa,
  tender_title: TextAa,
  transaction_id: Hash,
  logistics_party_name: TextAa,
  logistics_party_scac: TextAa,
  nvocc_scac: TextAa,
  vocc_scac: TextAa,
} satisfies Record<CoreFieldSearchFormMetadataName, Icon>

export const extensionFieldIcons = {
  boolean: Flag,
  freeform_text: TextAa,
  date_range: Calendar,
  numeric: Hash,
} satisfies Record<SearchFormInputType, Icon>

const convertActiveFieldstoFieldOrder = (activeFields: TabularSearchActiveFields) =>
  new Map(Array.from(activeFields).map((field, index) => [field, index]))
