import { StateStorage } from 'zustand/middleware'
import { get, set, del } from 'idb-keyval'
import { noop } from 'lodash'

export const storage = {
  getItem: async (name: string): Promise<string | null> => (await get(name)) || null,
  setItem: async (name: string, value: string): Promise<void> => await set(name, value),
  removeItem: async (name: string): Promise<void> => await del(name),
} satisfies StateStorage

export const noopStorage: StateStorage = {
  getItem: () => null,
  setItem: noop,
  removeItem: noop,
}
