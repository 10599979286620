import { Polygon } from 'geojson'
import { RiskList, StructuredAddress, UserSearchPreferencesV2 } from './API/generated/models'
import { UseFormHandleSubmit } from 'react-hook-form'
import { ReactNode } from 'react'
import {
  CompanySearchFieldsV2,
  CoreFieldSearchFormMetadata,
  CoreFieldSearchFormMetadataName,
  ExtensionFieldSearchFormMetadata,
  FacilitySearchFieldsV2,
  ProcurementSearchFieldsV2,
  SearchFormDefaultsResponse,
  SearchFormInputType,
  SearchTypeFormDefault,
  SearchTypeFormDefaultIncludeOptions,
  TransactionSearchFieldsV2,
} from './graph-relay/search/generated/models'

// #region field input types
export type SearchStringOptions = { label: string; value: string }[]
export type LocationSearchOptions = { label: string; value: StructuredAddress | string }[]
export type DateRange = {
  start_date: Date | null
  end_date: Date | null
}
export type ExposureOptions = {
  label: string
  value: Omit<RiskList, 'reference_urls'>
}[]
export type MapAreaInput = Omit<Polygon, 'bbox'> | null
export type NumericOperator = (typeof NumericOperator)[keyof typeof NumericOperator]
export const NumericOperator = {
  gt: 'gt',
  lt: 'lt',
  eq: 'eq',
} as const
export type NumericInput = {
  operator: NumericOperator
  count: string
}
export enum LegalStatus {
  ALL = 'All',
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}
export type LegalStatusField = {
  label: string
  value: string
}
// #endregion

// #region field default values
const defaultDateRange: DateRange = {
  start_date: null,
  end_date: null,
}

const defaultNumericInput: NumericInput = {
  operator: 'gt',
  count: '',
}

export const coreFieldDefaultValues = {
  awardee_name: [] as SearchStringOptions,
  awardee_organization_canon_id: [] as SearchStringOptions,
  bill_of_lading_number: [] as SearchStringOptions,
  bill_of_lading_numbers: [] as SearchStringOptions,
  bvd_id: [] as SearchStringOptions,
  company_canon_id: [] as SearchStringOptions,
  company_name: [] as SearchStringOptions,
  container_number: [] as SearchStringOptions,
  container_numbers: [] as SearchStringOptions,
  contract_end_date: { ...defaultDateRange },
  contract_start_date: { ...defaultDateRange },
  contracting_entity_country_iso2: [] as SearchStringOptions,
  contracting_entity_name: [] as SearchStringOptions,
  contracting_entity_organization_canon_id: [] as SearchStringOptions,
  country_of_destination_iso_alpha2: [] as SearchStringOptions,
  country_of_origin_iso_alpha2: [] as SearchStringOptions,
  country_of_registration_iso2: [] as SearchStringOptions,
  data_source_country: [] as SearchStringOptions,
  date_of_award: { ...defaultDateRange },
  derived_legal_status: {
    label: 'All',
    value: '',
  } as LegalStatusField,
  destination_company_canon_id: [] as SearchStringOptions,
  event_type: [] as SearchStringOptions,
  facility_canon_id: [] as SearchStringOptions,
  geo_location: null as MapAreaInput,
  goods_description: [] as SearchStringOptions,
  goods_descriptions_received_tokens: [] as SearchStringOptions,
  goods_descriptions_sent_tokens: [] as SearchStringOptions,
  hs_code: [] as SearchStringOptions,
  hs_codes_traded: [] as SearchStringOptions,
  imo_number: [] as SearchStringOptions,
  imo_numbers: [] as SearchStringOptions,
  nace_core_code4: [] as SearchStringOptions,
  number_of_shipments: { ...defaultNumericInput },
  operates_in: [] as LocationSearchOptions,
  port_of_destination_unlocode: [] as SearchStringOptions,
  port_of_lading_unlocode: [] as SearchStringOptions,
  port_of_origin_unlocode: [] as SearchStringOptions,
  port_of_unlading_unlocode: [] as SearchStringOptions,
  procurement_event_id: [] as SearchStringOptions,
  receiver_name: [] as SearchStringOptions,
  receives_from: [] as LocationSearchOptions,
  risk_exposures: [] as ExposureOptions,
  sender_name: [] as SearchStringOptions,
  sends_to: [] as LocationSearchOptions,
  shipment_date: { ...defaultDateRange },
  source_company_canon_id: [] as SearchStringOptions,
  source_record_id: [] as SearchStringOptions,
  tax_id: [] as SearchStringOptions,
  tax_ids: [] as SearchStringOptions,
  tender_description: [] as SearchStringOptions,
  tender_title: [] as SearchStringOptions,
  transaction_id: [] as SearchStringOptions,
  logistics_party_name: [] as SearchStringOptions,
  logistics_party_scac: [] as SearchStringOptions,
  nvocc_scac: [] as SearchStringOptions,
  vocc_scac: [] as SearchStringOptions,
} satisfies Record<CoreFieldSearchFormMetadataName, unknown>
export type CoreFieldDefaults = typeof coreFieldDefaultValues

export const extensionFieldDefaultValues = {
  boolean: false,
  freeform_text: [] as SearchStringOptions,
  date_range: { ...defaultDateRange },
  numeric: { ...defaultNumericInput },
} satisfies Record<SearchFormInputType, unknown>
export type ExtensionFieldDefaults = typeof extensionFieldDefaultValues
// #endregion

export type TabularSearchFormValue =
  | CoreFieldDefaults[keyof CoreFieldDefaults]
  | ExtensionFieldDefaults[keyof ExtensionFieldDefaults]
export type TabularSearchFormState = { [key: string]: TabularSearchFormValue }
export type TabularSearchFormFieldMetadata = Record<
  string,
  CoreFieldSearchFormMetadata | ExtensionFieldSearchFormMetadata
>
export type TabularSearchSubmission = {
  search_type: string
  label: string
  formData: TabularSearchFormState
  formFieldMetadata: TabularSearchFormFieldMetadata
  includeOptions?: SearchTypeFormDefaultIncludeOptions
}

export type TabularSearchFormProps = {
  onTabularSearch: (submission: TabularSearchSubmission) => void
  container?: HTMLElement | null
  tabularSearchDefaults: SearchTypeFormDefault
}

export type TabularSearchFieldOrder = Map<string, number>
export type TabularSearchActiveFields = Set<string>

export type TabularSearchInputState = {
  formStateByType: Record<string, TabularSearchFormState | undefined>
  activeFieldsByType: Record<string, TabularSearchActiveFields>
  fieldOrderByType: Record<string, TabularSearchFieldOrder>
  includeOptionsByType: Record<string, SearchTypeFormDefaultIncludeOptions>
  setTabularFormState: (tabularSearchType: string, formData?: TabularSearchFormState) => void
  initTabularSearchForms: (
    userPreferences: UserSearchPreferencesV2 | undefined,
    formDefaults: SearchFormDefaultsResponse,
  ) => string
  setActiveField: (tabularSearchType: string, field: string, active: boolean) => void
  changeFieldOrder: (tabularSearchType: string, orderedFields: string[]) => void
  setIncludeOption: (tabularSearchType: string, name: string, value: boolean) => void
}

export type AllSearchFieldsPayload = CompanySearchFieldsV2 &
  FacilitySearchFieldsV2 &
  TransactionSearchFieldsV2 &
  ProcurementSearchFieldsV2

// #region Path Search
export type PathState = {
  source: PathEntityFormState
  source_transaction: PathTransactionFormState
  intermediate: PathEntityFormState
  destination_transaction: PathTransactionFormState
  destination: PathEntityFormState
}

export type CompanySearchOptions = {
  label: string
  value: {
    is_ultimate_owner: boolean
    id: string
  }
}[]

export type PathEntityFormState = {
  companies: CompanySearchOptions
  countries: SearchStringOptions
  company_canon_id: SearchStringOptions
  risk_exposures: ExposureOptions
}

export type PathTransactionFormState = {
  hs_codes: SearchStringOptions
  transaction_dates: DateRange
}

export type PathForm = PathEntityFormState | PathTransactionFormState

export type PathStepSubmit = (onValid: (form: PathForm) => void, onInvalid: (errorMessage: string) => void) => void

export type PathStepProps = {
  formValues: PathForm
  updatePathForm: (form: PathForm) => void
  footer: (handleSubmit: UseFormHandleSubmit<PathForm>) => ReactNode
  isOptional: boolean
}
type PathStoreState = {
  path: PathState
  maxStep: number
  step: number
}
type PathStoreActions = {
  updatePath: (pathData: Partial<PathState>) => PathState
  resetPath: () => void
  updateMaxStep: (step: number) => void
  updateStep: (step: number) => void
}

export type PathSearchState = PathStoreState & PathStoreActions
// #endregion

// #region Companies List Search
export type CompaniesListSearchFormStoreState = { companies?: string }
type CompaniesListSearchFormStoreActions = {
  updateCompaniesList: (companies?: string) => void
  resetState: () => void
}
export type CompaniesListSearchFormState = CompaniesListSearchFormStoreState & CompaniesListSearchFormStoreActions
// #endregion
