import { create } from 'zustand'
import { Toast } from '@type/Toast'

interface ToastStore {
  toasts: Toast[]
  openToast: (values: Toast) => void
  isActive: (id: string | number) => boolean
  updateToast: (toast: Toast) => void
  closeToast: (id: string | number) => void
}

export const useToastStore = create<ToastStore>((set, get) => ({
  toasts: [] as Toast[],
  openToast: (toast: Toast) => {
    set(state => ({
      toasts: [...state.toasts, toast],
    }))
  },
  isActive: (id: string | number) => get().toasts.some(toast => toast.id === id),
  updateToast: (toast: Toast) =>
    set(state => ({
      toasts: state.toasts.map(item => {
        if (item.id === toast.id) {
          return {
            ...toast,
          }
        } else {
          return item
        }
      }),
    })),
  closeToast: (id: string | number) => {
    set(state => ({
      toasts: state.toasts.filter(toast => toast.id !== id),
    }))
  },
}))
