import React, { ReactNode, SyntheticEvent } from 'react'
import { logDebug } from '@utils/logger'
import { ToastStatus, Toast, ToastPosition } from '@type/Toast'
import { Provider, Root, Title, Description, Action, Viewport } from '@radix-ui/react-toast'
import styles from './Toast.module.css'
import { Button } from '../Button'
import clsx from 'clsx'
import { useToastStore } from '@stores/toastStore'
import { Spinner } from '@components/ui/Spinner/Spinner'
import { JobToast } from '@components/JobToast'
import { Check, CheckCircle, WarningDiamond, X } from '@phosphor-icons/react'
import { sprinkles } from '@styles/sprinkles.css'

export const getColorForStatus = (status?: ToastStatus) => {
  switch (status) {
    case 'success':
      return styles.Success
    case 'error':
      return styles.Error
    case 'info':
      return styles.Info
    default:
      return styles.Default
  }
}

export const getColorForCloseIcon = (status?: ToastStatus) => {
  switch (status) {
    case 'error':
    case 'warning':
    case 'success':
      return 'white'
    default:
      return 'black'
  }
}

const getPosition = (position?: ToastPosition) => {
  switch (position) {
    case 'bottom-right':
      return styles.BottomRight
    case 'bottom-left':
      return styles.BottomLeft
    case 'bottom-center':
      return styles.BottomCenter
    default:
      return styles.BottomLeft
  }
}

type PositionProps = {
  position: ToastPosition
}

export const StyledToastViewport = (props: PositionProps) => (
  <Viewport className={clsx(styles.ToastViewport, getPosition(props.position))} />
)

type IconProps = {
  status?: ToastStatus
}

export const ToastIcon = ({ status }: IconProps) => {
  switch (status) {
    case 'success':
      return <Check weight="bold" />
    case 'error':
      return <WarningDiamond weight="regular" />
    case 'loading':
      return <Spinner size={13} color="blue-6" />
    case 'successWhite':
      return <CheckCircle weight="bold" className={sprinkles({ color: 'green-5' })} />
    default:
      return null
  }
}

export const DefaultToast = ({
  title,
  status,
  description,
  closeIcon,
  closeButton,
  onDismiss,
  additionalContent,
  duration,
  raw,
  CustomIcon,
}: Toast) => {
  const dismissHandler = (event: SyntheticEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation()
    onDismiss?.()
  }
  return (
    <>
      <Root
        duration={duration ?? Infinity}
        className={clsx(
          styles.BaseToastRootStyles,
          raw ? styles.ToastRootRaw : styles.ToastRoot,
          getColorForStatus(status),
        )}
      >
        {status && !CustomIcon && (
          <div className={styles.Icon}>
            <ToastIcon status={status} />
          </div>
        )}

        {CustomIcon && <CustomIcon size={16} />}

        <div className={styles.ToastTextArea}>
          {title && <Title className={styles.ToastTitle}>{title}</Title>}

          {description && (
            <Description className={styles.ToastDescription} asChild>
              <p>{description}</p>
            </Description>
          )}
        </div>

        {closeIcon && (
          <Action asChild altText="Close alert" onPointerDown={dismissHandler}>
            <X
              alt="Close icon"
              className={clsx(styles.ToastCloseIconButton, sprinkles({ color: getColorForCloseIcon(status) }))}
            />
          </Action>
        )}
        {closeButton && (
          <Action asChild altText="Close alert" onPointerDown={dismissHandler}>
            <Button className={styles.ToastAction}>Close</Button>
          </Action>
        )}
        {additionalContent && <div className={styles.ToastButton}>{additionalContent}</div>}
      </Root>
    </>
  )
}

export const BaseToast = (options: Toast) => {
  const decoratedOptions: Toast = {
    ...options,
    duration: options.duration || 4000,
    closeIcon: options.closeIcon ?? true,
    closeButton: options.closeButton ?? false,
    position: options.position || 'bottom-left',
    status: options.status || 'info',
  }
  const { title, status, description, job } = decoratedOptions
  logDebug({ toast: { title, status, description } }, window.location.host)
  // jobs are in kg store, can't use hooks in store to trigger toasts, pass them through here.
  return <>{job ? <JobToast {...decoratedOptions} /> : <DefaultToast {...decoratedOptions} />}</>
}

export const ToastProvider = (props: { children?: ReactNode }) => {
  const { toasts } = useToastStore()
  return (
    <>
      {/* Radix refuses to have multiple viewports in the same provider, library limitation.
      Solution: Don't use radix, or nest providers.
      */}
      <Provider>
        {toasts &&
          toasts.map((toast: Toast) => toast.position === 'bottom-right' && <BaseToast key={toast.id} {...toast} />)}
        <StyledToastViewport position="bottom-right" />
        <Provider>
          {toasts &&
            toasts.map((toast: Toast) => toast.position === 'bottom-center' && <BaseToast key={toast.id} {...toast} />)}
          <StyledToastViewport position="bottom-center" />
          <Provider>
            {props.children}
            {toasts &&
              toasts.map((toast: Toast) => toast.position === 'bottom-left' && <BaseToast key={toast.id} {...toast} />)}
            <StyledToastViewport position="bottom-left" />
          </Provider>
        </Provider>
      </Provider>
    </>
  )
}
