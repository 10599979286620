import { createStore } from 'zustand'
import { PathState, PathSearchState, PathEntityFormState, PathTransactionFormState } from '@type/Search'

const default_entity_form: PathEntityFormState = {
  companies: [],
  countries: [],
  company_canon_id: [],
  risk_exposures: [],
}

const default_transaction_form: PathTransactionFormState = {
  hs_codes: [],
  transaction_dates: {
    start_date: new Date('01/08/2016'),
    end_date: new Date(),
  },
}

export const createPathSearchStore = (initialState: Partial<PathSearchState>) => {
  const defaults = {
    path: {
      source: { ...default_entity_form },
      source_transaction: { ...default_transaction_form },
      intermediate: { ...default_entity_form },
      destination_transaction: { ...default_transaction_form },
      destination: { ...default_entity_form },
    } satisfies PathState,
    step: 0,
    maxStep: 0,
  }
  return createStore<PathSearchState>()((set, get) => ({
    ...defaults,
    ...initialState,
    updatePath: (pathData: Partial<PathState>) => {
      const updatedPath = { ...get().path, ...pathData }
      set({ path: updatedPath })
      return updatedPath
    },
    updateMaxStep: (maxStep: number) => set({ maxStep }),
    updateStep: (step: number) => set({ step }),
    resetPath: () => set({ ...defaults }),
  }))
}
