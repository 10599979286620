import { UserSearchPreferencesV2 } from '@type/API/generated/models'
import { TabularSearchInputState, TabularSearchFormState } from '@type/Search'
import { SearchFormDefaultsResponse } from '@type/graph-relay/search/generated/models'
import { parseInitialTabularSearch } from '@utils/search'
import { createStore } from 'zustand'
import { devtools, subscribeWithSelector } from 'zustand/middleware'

export const createTabularSearchInputStore = (initialState: Partial<TabularSearchInputState>) => {
  const defaults = {
    formStateByType: {},
    activeFieldsByType: {},
    fieldOrderByType: {},
    includeOptionsByType: {},
  } satisfies Partial<TabularSearchInputState>

  return createStore<TabularSearchInputState>()(
    devtools(
      subscribeWithSelector((set, get) => ({
        ...defaults,
        setTabularFormState: (tabularSearchType: string, form?: TabularSearchFormState) => {
          const current = get().formStateByType
          const updated = { ...current, [tabularSearchType]: form }
          set({ formStateByType: updated })
        },
        initTabularSearchForms: (
          userPreferences: UserSearchPreferencesV2 | undefined,
          formDefaults: SearchFormDefaultsResponse,
        ) => {
          const { activeFieldsByType, fieldOrderByType, formStateByType, includeOptionsByType, initialSearchName } =
            parseInitialTabularSearch(formDefaults, userPreferences)
          set({
            activeFieldsByType,
            fieldOrderByType,
            formStateByType,
            includeOptionsByType,
          })
          return initialSearchName
        },
        setActiveField: (tabularSearchType: string, field: string, active: boolean) => {
          const currentActiveFields = new Set(get().activeFieldsByType?.[tabularSearchType])
          if (!currentActiveFields) return
          active ? currentActiveFields.add(field) : currentActiveFields.delete(field)
          set({
            activeFieldsByType: { ...get().activeFieldsByType, [tabularSearchType]: currentActiveFields },
          })
        },
        changeFieldOrder: (tabularSearchType: string, orderedFields: string[]) => {
          const newOrder = new Map<string, number>()
          orderedFields.forEach((field, index) => newOrder.set(field, index))
          set({ fieldOrderByType: { ...get().fieldOrderByType, [tabularSearchType]: newOrder } })
        },
        setIncludeOption: (tabularSearchType: string, name: string, value: boolean) => {
          const currentOptionsByType = get().includeOptionsByType
          const targetOption = currentOptionsByType[tabularSearchType]?.[name]
          if (targetOption) {
            const updated = {
              ...currentOptionsByType,
              [tabularSearchType]: {
                ...currentOptionsByType[tabularSearchType],
                [targetOption.name]: { ...targetOption, value: value },
              },
            }
            set({ includeOptionsByType: updated })
          }
        },
        ...initialState,
      })),
    ),
  )
}
