/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.0.0
 */

export type SearchFormInputType = (typeof SearchFormInputType)[keyof typeof SearchFormInputType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SearchFormInputType = {
  freeform_text: 'freeform_text',
  date_range: 'date_range',
  numeric: 'numeric',
  boolean: 'boolean',
} as const
