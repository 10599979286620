import { createContext, PropsWithChildren, useEffect } from 'react'
import { useUserStore } from '@stores/userStore'
import { useGetUserInfo } from '@type/API/generated/internal-v3-admin/internal-v3-admin'
import { logError } from '@utils/logger'
import { setMetricsUser } from '@utils/metrics'
import { GetUserInfo200 } from '@type/API/generated/models'

export const UserContext = createContext<GetUserInfo200 | undefined>(undefined)

export const UserProvider = ({ children }: PropsWithChildren) => {
  const { setKeycloakCredentials } = useUserStore()
  const { data, error, isLoading } = useGetUserInfo() // revalidation will trigger auth check (via api) and auto redirect to logout

  useEffect(() => {
    if (data) {
      setKeycloakCredentials(data)
    }
    setMetricsUser({
      id: data?.user_id,
      name: data?.name,
      email: data?.email,
      isInternalAltanaUser: Boolean(data?.email?.toLowerCase().endsWith('@altana.ai')).toString(),
    })
  }, [data, setKeycloakCredentials])

  if (error) {
    logError(error)
    return <div data-testid="generic-error" />
  }

  if (isLoading && !data) return <div data-testid="loading" />

  if (!data?.user_id) {
    logError('No User ID present.')
    return <div data-testid="user-error" />
  }

  return <UserContext.Provider value={data}>{children}</UserContext.Provider>
}
