import { datadogRum } from '@datadog/browser-rum'
import env from '@beam-australia/react-env'
import { logError } from './logger'

const isEnabled = Boolean(env('DATADOG_RUM_APPLICATION_ID'))

export const initMetrics = () => {
  if (!isEnabled) return

  try {
    datadogRum.init({
      applicationId: env('DATADOG_RUM_APPLICATION_ID'),
      clientToken: env('DATADOG_RUM_CLIENT_TOKEN'),
      site: env('DATADOG_RUM_SITE'),
      service: env('DATADOG_RUM_SERVICE'),
      env: env('DATADOG_RUM_ENVIRONMENT'),
      allowedTracingOrigins: [env('API_BASE_URL')],
      version: env('VERSION'),
      sampleRate: 100,
      premiumSampleRate: 100,
      trackInteractions: true,
      defaultPrivacyLevel: 'mask-user-input',
    })
    datadogRum.startSessionReplayRecording()
  } catch (error) {
    logError(error)
  }
}

interface MetricsUser {
  id: string
  name?: string
  email?: string
  isInternalAltanaUser?: string
  [key: string]: unknown
}
export const setMetricsUser = (user: MetricsUser | null) => {
  if (!isEnabled) return

  try {
    if (user?.id) {
      datadogRum.setUser(user)
    } else {
      datadogRum.removeUser()
    }
  } catch (error) {
    logError(error)
  }
}

export const logMetric = (...args: Parameters<typeof datadogRum.addAction>) => {
  const [name, context] = args

  if (!isEnabled) return

  try {
    datadogRum.addAction(name, context)
  } catch (error) {
    logError(error)
  }
}
