/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Altana Atlas API
 * The Atlas API allows developers to programmatically access Altana's knowledge graph. This API can be used to search for
entities in the graph and discover relationships between given entities.

# Introduction
Altana's knowledge graph is comprised of nodes and directed edges, all of which have a canonical ID that uniquely identify
the node or edge. Node IDs follow the naming pattern `[node_type]_canon_id` (i.e. `company_canon_id`) and edge IDs follow
the pattern `[edge_type]_id` (i.e. `company_sends_to_id`). All entities have either a `node_type` or an `edge_type` field
that dictates their type (see the `/types` endpoint for all the available types), and all edges have the fields `source_id` and
`destination_id` that contain pointers to the source and destination node, describing the direction of the edge.

# Authentication
Requests are authenticated using an API token that is expected in the `X-Api-Key` header.

# Additional Fields
An important concept to understand is the notion of additional fields. All nodes and edges have a field called
`additional_fields` which is only populated when getting a resource by it's ID, and will be `null` in responses that return
multiple entities. The fields that are documented in the schema of a node or edge are considered core fields and will always
be returned, but the underlying entities in the knowledge graph have more fields associated with them. When `additional_fields`
is populated, it will be a JSON object that contains all other fields (not the fields that are in the schema) and their values
that are stored in the graph for that node or edge.

This is done in order to reduce the size of the payload for requests that return multiple entities.

 * OpenAPI spec version: {{ version or "v0.0.1" }}
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  Error,
  TypeaheadSuggestions,
  TypeaheadUserProfileParams,
  UpdateUserProfileBodyBody,
  UpdateUserProfileParams,
  UserProfile
} from '.././models'
import { customInstance } from '../../custom-instance';



/**
 * @summary Updates the current user's profile
 */
export const updateUserProfile = (
    updateUserProfileBodyBody: UpdateUserProfileBodyBody,
    params?: UpdateUserProfileParams,
 ) => {
      
      
      return customInstance<UserProfile>(
      {url: `/internal/v3/user_profile`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: updateUserProfileBodyBody,
        params
    },
      );
    }
  


export const getUpdateUserProfileMutationOptions = <TError = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateUserProfile>>, TError,{data: UpdateUserProfileBodyBody;params?: UpdateUserProfileParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof updateUserProfile>>, TError,{data: UpdateUserProfileBodyBody;params?: UpdateUserProfileParams}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateUserProfile>>, {data: UpdateUserProfileBodyBody;params?: UpdateUserProfileParams}> = (props) => {
          const {data,params} = props ?? {};

          return  updateUserProfile(data,params,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateUserProfileMutationResult = NonNullable<Awaited<ReturnType<typeof updateUserProfile>>>
    export type UpdateUserProfileMutationBody = UpdateUserProfileBodyBody
    export type UpdateUserProfileMutationError = void

    /**
 * @summary Updates the current user's profile
 */
export const useUpdateUserProfile = <TError = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateUserProfile>>, TError,{data: UpdateUserProfileBodyBody;params?: UpdateUserProfileParams}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof updateUserProfile>>,
        TError,
        {data: UpdateUserProfileBodyBody;params?: UpdateUserProfileParams},
        TContext
      > => {

      const mutationOptions = getUpdateUserProfileMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Get typeahead suggestions for users.
 */
export const typeaheadUserProfile = (
    params: TypeaheadUserProfileParams,
 signal?: AbortSignal
) => {
      
      
      return customInstance<TypeaheadSuggestions>(
      {url: `/internal/v3/user_profile/typeahead`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getTypeaheadUserProfileQueryKey = (params: TypeaheadUserProfileParams,) => {
    return [`/internal/v3/user_profile/typeahead`, ...(params ? [params]: [])] as const;
    }

    
export const getTypeaheadUserProfileQueryOptions = <TData = Awaited<ReturnType<typeof typeaheadUserProfile>>, TError = Error>(params: TypeaheadUserProfileParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof typeaheadUserProfile>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTypeaheadUserProfileQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof typeaheadUserProfile>>> = ({ signal }) => typeaheadUserProfile(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof typeaheadUserProfile>>, TError, TData> & { queryKey: QueryKey }
}

export type TypeaheadUserProfileQueryResult = NonNullable<Awaited<ReturnType<typeof typeaheadUserProfile>>>
export type TypeaheadUserProfileQueryError = Error

/**
 * @summary Get typeahead suggestions for users.
 */
export const useTypeaheadUserProfile = <TData = Awaited<ReturnType<typeof typeaheadUserProfile>>, TError = Error>(
 params: TypeaheadUserProfileParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof typeaheadUserProfile>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getTypeaheadUserProfileQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



