/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.0.0
 */

export type SearchFormGroup = (typeof SearchFormGroup)[keyof typeof SearchFormGroup]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SearchFormGroup = {
  identifiers: 'identifiers',
  location: 'location',
  transaction_details: 'transaction_details',
  transaction_identifiers: 'transaction_identifiers',
  other_business_identifiers: 'other_business_identifiers',
} as const
