/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.0.0
 */

export type CompanyNodeCollectionType = (typeof CompanyNodeCollectionType)[keyof typeof CompanyNodeCollectionType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyNodeCollectionType = {
  company: 'company',
} as const
