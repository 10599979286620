/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.0.0
 */

export type GroupNodeCollectionType = (typeof GroupNodeCollectionType)[keyof typeof GroupNodeCollectionType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GroupNodeCollectionType = {
  group: 'group',
} as const
