/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.0.0
 */

export type Direction = (typeof Direction)[keyof typeof Direction]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Direction = {
  ASC: 'ASC',
  DESC: 'DESC',
} as const
