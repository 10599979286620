/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.0.0
 */

export type LionCompanyNodeCollectionType =
  (typeof LionCompanyNodeCollectionType)[keyof typeof LionCompanyNodeCollectionType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LionCompanyNodeCollectionType = {
  lion_company: 'lion_company',
} as const
