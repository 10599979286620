import { sprinkles, Sprinkles } from '@styles/sprinkles.css'
import clsx from 'clsx'
import { AriaAttributes, CSSProperties } from 'react'

export type SpinnerProps = {
  /** Controls the size of the spinner and the size of label text (if provided) */
  size?: Sprinkles['fontSize']
  /** Sets both the color of the spinner and label text (if provided) */
  color?: Sprinkles['color']
  /** Additional classList applied to the outermost wrapper of this component */
  className?: string
  /** Text displayed next to the loading spinner  */
  label?: string
  /** Color for the text displayed next to the loading spinner */
  labelColor?: Sprinkles['color']
  style?: CSSProperties
} & AriaAttributes

const loadingAnimationClass = sprinkles({ animation: 'spin' })

export const Spinner = ({ size = 15, color, className, label, labelColor, ...rest }: SpinnerProps) => {
  const coreClasses = sprinkles({
    display: 'inline-flex',
    alignItems: 'center',
    height: size === 24 ? 40 : size === 20 ? 32 : 24,
    fontSize: size,
    color,
    gap: 8,
  })

  return (
    <div className={clsx(coreClasses, className)} {...rest}>
      <svg width="1.5em" height="1.5em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <title>Spinner</title>
        <path
          className={loadingAnimationClass}
          d="M3.05493 11C3.55238 6.50005 7.36745 3 12 3C16.6326 3 20.4476 6.50005 20.9451 11"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      {label && (
        <span
          className={sprinkles({
            color: labelColor,
          })}
        >
          {label}
        </span>
      )}
    </div>
  )
}
