/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.0.0
 */

export type NodeType = (typeof NodeType)[keyof typeof NodeType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NodeType = {
  company: 'company',
  director: 'director',
  facility: 'facility',
  risk_entity: 'risk_entity',
  shareholder: 'shareholder',
} as const
