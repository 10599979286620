/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.0.0
 */

export type ProcurementCoreSearchFields = (typeof ProcurementCoreSearchFields)[keyof typeof ProcurementCoreSearchFields]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProcurementCoreSearchFields = {
  awardee_name: 'awardee_name',
  awardee_organization_canon_id: 'awardee_organization_canon_id',
  contract_start_date: 'contract_start_date',
  contract_end_date: 'contract_end_date',
  contracting_entity_country_iso2: 'contracting_entity_country_iso2',
  contracting_entity_name: 'contracting_entity_name',
  contracting_entity_organization_canon_id: 'contracting_entity_organization_canon_id',
  date_of_award: 'date_of_award',
  event_type: 'event_type',
  procurement_event_id: 'procurement_event_id',
  tender_description: 'tender_description',
  tender_title: 'tender_title',
} as const
