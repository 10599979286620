import { Button } from '@components/ui/Button'
import { useJobStore } from '@stores/jobStore'
import { logError } from '@utils/logger'
import { format, fromUnixTime } from 'date-fns'
import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { Toast, ToastStatus } from '@type/Toast'
import { ToastIcon, getColorForCloseIcon, getColorForStatus } from '@components/ui/Toast/Toast'
import styles from '@components/ui/Toast/Toast.module.css'
import { Root, Title, Action } from '@radix-ui/react-toast'
import clsx from 'clsx'
import { Spinner } from '@components/ui/Spinner/Spinner'
import { downloadBlobAsFile } from '@utils/file'
import { sprinkles } from '@styles/sprinkles.css'
import { ArrowCounterClockwise, DownloadSimple, X } from '@phosphor-icons/react'

export const JobToast = (options: Toast) => {
  const [toastStatus, setToastStatus] = useState<ToastStatus>('default')
  const [isFetching, setIsFetching] = useState(false)
  const { removeJob, replaceJob } = useJobStore()
  const { job, duration, closeIcon, closeButton } = options

  // Keep status in state rather than memoized
  // If memoized, when the job is removed, the status is set to undefined and turns toast visually into "info" before the closing animation completes
  useEffect(() => {
    switch (job?.statusReport.status) {
      case 'FAILED':
        return setToastStatus('error')
      case 'COMPLETE':
        return setToastStatus('success')
      default:
        return setToastStatus('info')
    }
  }, [job?.statusReport.status])

  const fileName = job?.description
    ? `${job.description} ${format(fromUnixTime(job.startTS), 'LLL-d-y-p')}.${job.fileFormat}`
    : ''

  const onDownloadClick = useCallback(
    (event: SyntheticEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation()
      if (!job?.id || !job || !job.fetchJobContent) return
      setIsFetching(true)

      job
        .fetchJobContent(job.id)
        .then(response => {
          downloadBlobAsFile(response, fileName)
          removeJob(job.id)
        })
        .finally(() => setIsFetching(false))
    },
    [fileName, job, removeJob],
  )

  const onRetryExport = useCallback(
    (event: SyntheticEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation()
      if (!job) return
      setIsFetching(true)
      job
        .remakeJob()
        .then(export_job_id => {
          if (export_job_id) {
            replaceJob(job.id, export_job_id)
          }
        })
        .catch((error: unknown) => {
          // If the initial export fails, user can just click the retry button again
          logError(error)
        })
        .finally(() => setIsFetching(false))
    },
    [job, replaceJob],
  )

  const toastContent = useMemo(() => {
    if (!job?.fetchJobContent) return
    if (job?.statusReport.status === 'COMPLETE') {
      if (isFetching) {
        return (
          <div className={sprinkles({ display: 'flex', justifyContent: 'center' })}>
            <span className={sprinkles({ paddingRight: 8 })}>Accessing export</span>
            <Spinner size={17} />
          </div>
        )
      } else {
        return (
          <Button
            pill
            variant="secondary"
            className={sprinkles({ margin: 'auto' })}
            onPointerDown={onDownloadClick}
            aria-label="Download File"
          >
            <DownloadSimple size={20} /> Download File
          </Button>
        )
      }
    }
    if (job?.statusReport.status === 'CANCELLED' || job?.statusReport.status === 'FAILED') {
      return (
        <Button pill variant="secondary" className={sprinkles({ margin: 'auto' })} onPointerDown={onRetryExport}>
          <ArrowCounterClockwise size={20} /> Retry Export
        </Button>
      )
    }
  }, [isFetching, job, onDownloadClick, onRetryExport])

  const onDismiss = (event: SyntheticEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation()
    if (job) removeJob(job.id)
  }

  const toastTitle = useMemo(() => {
    switch (job?.statusReport.status) {
      case 'CANCELLED':
        return `${job?.jobType} of ${job?.description} was cancelled`
      case 'FAILED':
        return `${job?.jobType} of ${job?.description} failed`
      case 'COMPLETE':
        return `${job?.jobType} of ${job?.description} completed`
      default:
        return ''
    }
  }, [job?.description, job?.statusReport.status, job?.jobType])

  if (job?.statusReport.status === 'PENDING' || !job?.showToast) return null

  return (
    <Root
      duration={duration ? duration : Infinity}
      className={clsx(styles.BaseToastRootStyles, styles.ToastRoot, getColorForStatus(toastStatus))}
    >
      {job?.statusReport && (
        <div className={styles.Icon}>
          <ToastIcon status={'success'} />
        </div>
      )}

      <div className={styles.TextArea}>{toastTitle && <Title className={styles.ToastTitle}>{toastTitle}</Title>}</div>

      {closeIcon && (
        <Action asChild altText="Close alert" onPointerDown={onDismiss}>
          <X className={clsx(styles.ToastCloseIconButton, sprinkles({ color: getColorForCloseIcon(toastStatus) }))} />
        </Action>
      )}
      {closeButton && (
        <Action asChild altText="Close alert" onPointerDown={onDismiss}>
          <Button className={styles.ToastAction}>Close</Button>
        </Action>
      )}
      {/* This is where items like download buttons etc will appear */}
      {toastContent && <div className={styles.ToastButton}>{toastContent}</div>}
    </Root>
  )
}
