import { Job, JobInput } from '@type/Job'
import { checkJobStatus, updateJobStatusInState } from '@utils/job'
import { create } from 'zustand'

export type JobState = {
  _hydrated: boolean
  jobs: Map<string, Job>
  saveJob: (newJobParams: JobInput) => Job | undefined
  removeJob: (id: string) => void // Called after downloading
  replaceJob: (oldId: string, newId: string) => void
}

export const useJobStore = create<JobState>()((set, get) => ({
  _hydrated: Boolean(false),
  jobs: new Map(),
  saveJob: (newJobParams: JobInput) => {
    const {
      id,
      description,
      remakeJob,
      fileFormat,
      fetchStatusReport,
      fetchJobContent,
      jobType = 'Export',
      showToast = true,
    } = newJobParams
    const newJob: Job = {
      id,
      description,
      startTS: Date.now() / 1000,
      statusReport: { status: 'PENDING' },
      remakeJob,
      fileFormat,
      fetchStatusReport,
      fetchJobContent,
      jobType,
      showToast,
    }
    set(state => ({
      jobs: new Map(state.jobs).set(id, newJob),
    }))
    checkJobStatus(id, status => set(state => updateJobStatusInState(id, status, state)), fetchStatusReport)
    return get().jobs.get(id)
  },
  removeJob: (id: string) =>
    set(state => {
      const deleted = state.jobs.delete(id)
      return deleted ? { jobs: new Map(state.jobs) } : state
    }),
  replaceJob: (oldId: string, newId: string) => {
    const oldJob = get().jobs.get(oldId)
    if (!oldJob) return
    get().saveJob({
      id: newId,
      description: oldJob.description,
      remakeJob: oldJob.remakeJob,
      fileFormat: oldJob.fileFormat,
      fetchStatusReport: oldJob.fetchStatusReport,
      fetchJobContent: oldJob.fetchJobContent,
      jobType: oldJob.jobType,
      showToast: oldJob.showToast,
    })
    get().removeJob(oldId)
  },
}))
