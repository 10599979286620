/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.0.0
 */

export type FacilitySearchRequestCollection =
  (typeof FacilitySearchRequestCollection)[keyof typeof FacilitySearchRequestCollection]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FacilitySearchRequestCollection = {
  facility: 'facility',
} as const
