/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.0.0
 */

export type ExplorationColumnType = (typeof ExplorationColumnType)[keyof typeof ExplorationColumnType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExplorationColumnType = {
  dateColumn: 'dateColumn',
  textColumn: 'textColumn',
  numberColumn: 'numberColumn',
  countryColumn: 'countryColumn',
  arrayColumn: 'arrayColumn',
  booleanColumn: 'booleanColumn',
  companySearchCompanyProfileLinkColumn: 'companySearchCompanyProfileLinkColumn',
  companySearchOwnedByProfileLinkColumn: 'companySearchOwnedByProfileLinkColumn',
  facilitySearchCompanyProfileLinkColumn: 'facilitySearchCompanyProfileLinkColumn',
  transactionSearchSenderProfileLinkColumn: 'transactionSearchSenderProfileLinkColumn',
  transactionSearchReceiverProfileLinkColumn: 'transactionSearchReceiverProfileLinkColumn',
  procurementSearchAwardeeProfileLinkColumn: 'procurementSearchAwardeeProfileLinkColumn',
  procurementSearchContractingProfileLinkColumn: 'procurementSearchContractingProfileLinkColumn',
} as const
