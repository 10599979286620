/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.0.0
 */

export type TransactionSearchRequestV2Collection =
  (typeof TransactionSearchRequestV2Collection)[keyof typeof TransactionSearchRequestV2Collection]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransactionSearchRequestV2Collection = {
  transaction: 'transaction',
} as const
