import { useMemo } from 'react'
import { useUserStore } from '@stores/userStore'

export const useUser = () => {
  const auth0UserId = useUserStore(userStore => userStore.userId)
  const legacyUserId = useUserStore(userStore => userStore.legacyUserId)
  const shouldUseAuth0 = useUserStore(userStore => !userStore.apiKey)

  const userId = useMemo(
    () => (shouldUseAuth0 ? auth0UserId : legacyUserId),
    [auth0UserId, legacyUserId, shouldUseAuth0],
  )

  return { userId }
}
