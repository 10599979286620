import { useUserStore } from '@stores/userStore'
import { useEffect } from 'react'
import { useLoggedIn } from './useLoggedIn'

export const useLoadUserInfo = () => {
  const loadUserInfoIntoStore = useUserStore(state => state.loadUserInfo)
  const isLoggedIn = useLoggedIn()

  useEffect(() => {
    if (isLoggedIn) {
      loadUserInfoIntoStore()
    }
  }, [isLoggedIn, loadUserInfoIntoStore])
}
