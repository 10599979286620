/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.0.0
 */

export type ProcurementNodeCollectionType =
  (typeof ProcurementNodeCollectionType)[keyof typeof ProcurementNodeCollectionType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProcurementNodeCollectionType = {
  procurement: 'procurement',
} as const
