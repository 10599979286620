/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.0.0
 */

export type TabularSearchTypeIcon = (typeof TabularSearchTypeIcon)[keyof typeof TabularSearchTypeIcon]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TabularSearchTypeIcon = {
  buildings: 'buildings',
  factory: 'factory',
  boat: 'boat',
  note: 'note',
} as const
