/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.0.0
 */

export type SearchFilterOperator = (typeof SearchFilterOperator)[keyof typeof SearchFilterOperator]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SearchFilterOperator = {
  lt: 'lt',
  lte: 'lte',
  gt: 'gt',
  gte: 'gte',
  eq: 'eq',
} as const
