/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.0.0
 */

export type CompanyCoreSearchFields = (typeof CompanyCoreSearchFields)[keyof typeof CompanyCoreSearchFields]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyCoreSearchFields = {
  bill_of_lading_numbers: 'bill_of_lading_numbers',
  bvd_id: 'bvd_id',
  company_canon_id: 'company_canon_id',
  company_name: 'company_name',
  container_numbers: 'container_numbers',
  country_of_registration_iso2: 'country_of_registration_iso2',
  derived_legal_status: 'derived_legal_status',
  geo_location: 'geo_location',
  goods_descriptions_received_tokens: 'goods_descriptions_received_tokens',
  goods_descriptions_sent_tokens: 'goods_descriptions_sent_tokens',
  hs_codes_traded: 'hs_codes_traded',
  imo_numbers: 'imo_numbers',
  number_of_shipments: 'number_of_shipments',
  shipment_date: 'shipment_date',
  nace_core_code4: 'nace_core_code4',
  operates_in: 'operates_in',
  receives_from: 'receives_from',
  risk_exposures: 'risk_exposures',
  sends_to: 'sends_to',
  tax_ids: 'tax_ids',
} as const
