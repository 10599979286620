import env from '@beam-australia/react-env'
import { PropsWithChildren } from 'react'

const FEATURE_FLAGS = {
  facilities_page_sidebar: 'FEATURE_FACILITIES_PAGE_SIDEBAR',
  explorations_phone_number: 'FEATURE_EXPLORATIONS_PHONE_NUMBER',
  trade_compliance_gateway: 'FEATURE_TRADE_COMPLIANCE_GATEWAY',
  generative_search: 'FEATURE_NLGC_ENABLED',
  keycloak_auth: 'KEYCLOAK_AUTH_ENABLED',
  pendo_guides: 'FEATURE_PENDO_GUIDES',
  quicksight_integration: 'FEATURE_QUICKSIGHT_INTEGRATION',
  disable_vc_ui: 'FEATURE_DISABLE_VC_UI',
  disable_saved_workspaces_ui: 'FEATURE_DISABLE_SAVED_WORKSPACES_UI',
  earhart_enabled: 'FEATURE_EARHART_ENABLED',
  counternarcotics: 'FEATURE_TCG_COUNTERNARCOTICS_ENABLED',
  search_transshipment_tab: 'FEATURE_SEARCH_TRANSSHIPMENT_TAB',
  search_company_list_tab: 'FEATURE_SEARCH_COMPANY_LIST_TAB',
  search_results_selectable: 'FEATURE_SEARCH_RESULTS_SELECTABLE',
  use_auth0: 'USE_AUTH0',
  support_dual_auth: 'SUPPORT_DUAL_AUTH',
  lion_company_fields_enabled: 'LION_COMPANY_FIELDS_ENABLED',
  explorations_procurement: 'FEATURE_EXPLORATIONS_PROCUREMENT',
  exploration_global_filters: 'FEATURE_EXPLORATIONS_GLOBAL_FILTERS',
  explorations_attribute_filter: 'FEATURE_EXPLORATIONS_ATTRIBUTE_FILTER',
  explorations_group_by_attribute: 'FEATURE_EXPLORATIONS_GROUP_BY_ATTRIBUTE',
  explorations_ungrouping_actions: 'FEATURE_EXPLORATIONS_UNGROUPING_ACTIONS',
  explorations_highlight_network: 'FEATURE_EXPLORATIONS_HIGHLIGHT_NETWORK',
  misvaluation_enabled: 'FEATURE_MISVALUATION_ENABLED',
  reports_enabled: 'REPORTS_ENABLED',
  dataminr_enabled: 'DATAMINR_ENABLED',
  lenses: 'FEATURE_LENSES',
  vc_refinement_enabled: 'VC_REFINEMENT_ENABLED',
  vc_refinement_app_filtering_enabled: 'VC_REFINEMENT_APP_FILTERING_ENABLED',
  collaboration_enabled: 'COLLABORATION_ENABLED',
  scarbrough_logo_enabled: 'FEATURE_SCARBROUGH_LOGO_ENABLED', // Temporary FF for custom Scarbrough logo until better solution is reached
  tc_copilot_v2: 'FEATURE_TC_COPILOT_V2',
  spoke_selector: 'FEATURE_SPOKE_SELECTOR',
  company_profile_tabs_list: 'FEATURE_COMPANY_PROFILE_TABS_LIST',
  company_profile_actions: 'FEATURE_COMPANY_PROFILE_ACTIONS',
  manage_catalog: 'FEATURE_MANAGE_CATALOG',
  user_profile_typeahead_enabled: 'USER_PROFILE_TYPEAHEAD_ENABLED',
  explorations_view_only_sharing_enabled: 'EXPLORATIONS_VIEW_ONLY_SHARING_ENABLED',
  catalog_extensions_read_enabled: 'CATALOG_EXTENSIONS_READ_ENABLED',
} as const

const FEATURE_LABELS = {
  product_group: { var: 'PRODUCT_GROUPS_NAME_OVERRIDE', default: 'Product' },
  product_variant: { var: 'PRODUCT_VARIANTS_NAME_OVERRIDE', default: 'Product Variant' },
  user_info_url: { var: 'USER_INFO_URL', default: '' },
  change_password_path: {
    var: 'CHANGE_PASSWORD_PATH',
    default: '',
  },
} as const

export const isFeatureEnabled = (name: keyof typeof FEATURE_FLAGS) => env(FEATURE_FLAGS[name]) === 'true'

export const getFeatureValues = (name: keyof typeof FEATURE_FLAGS) => env(FEATURE_FLAGS[name])

export const getFeatureLabel = (name: keyof typeof FEATURE_LABELS) =>
  env(FEATURE_LABELS[name].var) || FEATURE_LABELS[name].default

type FeatureProps = PropsWithChildren<{
  name: keyof typeof FEATURE_FLAGS
}>

/**
 * This acts as a wrapper, and if the env being used has a 'true' boolean value set,
 * then the feature will render it's wrapped children.
 * Otherwise, it returns null.
 *  */
export const Feature = ({ name, children }: FeatureProps) => {
  const isFlagged = isFeatureEnabled(name)

  if (isFlagged) {
    return <>{children}</>
  }
  return null
}

export type RuntimeEnvironment = {
  [P in (typeof FEATURE_FLAGS)[keyof typeof FEATURE_FLAGS] as `REACT_APP_${P}`]: string
} & {
  [P in (typeof FEATURE_LABELS)[keyof typeof FEATURE_LABELS]['var'] as `REACT_APP_${P}`]: string
} & {
  REACT_APP_AG_GRID_LICENSE_KEY: string
  REACT_APP_SQUADRON_BASE_URL: string
  REACT_APP_COLLABORATION_DOMAIN: string
  REACT_APP_BUILD_VERSION: string
  REACT_APP_CI_COMMIT_SHORT_SHA: string
}

declare global {
  interface Window {
    __ENV: Partial<RuntimeEnvironment>
  }
}
