export const downloadBlobAsFile = (blob: Blob, filename: string): void => {
  const tempObjectUrl = URL.createObjectURL(blob)
  const tempLink = Object.assign(document.createElement('a'), {
    target: '_blank',
    download: filename,
    href: tempObjectUrl,
  })
  tempLink.click()
  URL.revokeObjectURL(tempObjectUrl)
}
