/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.0.0
 */

export type FacilityNodeCollectionType = (typeof FacilityNodeCollectionType)[keyof typeof FacilityNodeCollectionType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FacilityNodeCollectionType = {
  facility: 'facility',
} as const
