/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Altana Atlas API
 * The Atlas API allows developers to programmatically access Altana's knowledge graph. This API can be used to search for
entities in the graph and discover relationships between given entities.

# Introduction
Altana's knowledge graph is comprised of nodes and directed edges, all of which have a canonical ID that uniquely identify
the node or edge. Node IDs follow the naming pattern `[node_type]_canon_id` (i.e. `company_canon_id`) and edge IDs follow
the pattern `[edge_type]_id` (i.e. `company_sends_to_id`). All entities have either a `node_type` or an `edge_type` field
that dictates their type (see the `/types` endpoint for all the available types), and all edges have the fields `source_id` and
`destination_id` that contain pointers to the source and destination node, describing the direction of the edge.

# Authentication
Requests are authenticated using an API token that is expected in the `X-Api-Key` header.

# Additional Fields
An important concept to understand is the notion of additional fields. All nodes and edges have a field called
`additional_fields` which is only populated when getting a resource by it's ID, and will be `null` in responses that return
multiple entities. The fields that are documented in the schema of a node or edge are considered core fields and will always
be returned, but the underlying entities in the knowledge graph have more fields associated with them. When `additional_fields`
is populated, it will be a JSON object that contains all other fields (not the fields that are in the schema) and their values
that are stored in the graph for that node or edge.

This is done in order to reduce the size of the payload for requests that return multiple entities.

 * OpenAPI spec version: {{ version or "v0.0.1" }}
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  APIResourceCapabilities,
  AcceptAllPendingResourcesParams,
  ActiveValueChainJobsCount,
  AuditLogRequestBodyBody,
  AuditLogResponse,
  DeleteAllSorDataParams,
  Error,
  FilterValues,
  GetCountryIso2Mapping200,
  GetUserInfo200,
  GetValueChainCreationArgumentsV3RequestBody,
  GetValueChainHistoricalCreationArgsParams,
  InlineObjectBody,
  IsUserAuthorizedForResourceCapabilityRequestBodyBody,
  JobStatusReport,
  ListValueChainJobsParams,
  PostAuditLogFilterValuesParams,
  PostAuditLogParams,
  ProcessingSuppliers,
  RiskListConfigs,
  UpdateRiskListConfigRequestBody,
  UpsertValueChainCreationArgumentsV3RequestBody,
  ValueChainCreationArgumentsV3,
  ValueChainJobs
} from '.././models'
import { customInstance } from '../../custom-instance';



/**
 * @summary Accept all pending resources (including goods, suppliers, and value chains)
 */
export const acceptAllPendingResources = (
    params?: AcceptAllPendingResourcesParams,
 ) => {
      
      
      return customInstance<void>(
      {url: `/internal/v3/admin/accept_all_pending_resources`, method: 'POST',
        params
    },
      );
    }
  


export const getAcceptAllPendingResourcesMutationOptions = <TError = Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof acceptAllPendingResources>>, TError,{params?: AcceptAllPendingResourcesParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof acceptAllPendingResources>>, TError,{params?: AcceptAllPendingResourcesParams}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof acceptAllPendingResources>>, {params?: AcceptAllPendingResourcesParams}> = (props) => {
          const {params} = props ?? {};

          return  acceptAllPendingResources(params,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AcceptAllPendingResourcesMutationResult = NonNullable<Awaited<ReturnType<typeof acceptAllPendingResources>>>
    
    export type AcceptAllPendingResourcesMutationError = Error

    /**
 * @summary Accept all pending resources (including goods, suppliers, and value chains)
 */
export const useAcceptAllPendingResources = <TError = Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof acceptAllPendingResources>>, TError,{params?: AcceptAllPendingResourcesParams}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof acceptAllPendingResources>>,
        TError,
        {params?: AcceptAllPendingResourcesParams},
        TContext
      > => {

      const mutationOptions = getAcceptAllPendingResourcesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get the audit log for the given resource label.
 * @summary Get Audit Log
 */
export const postAuditLog = (
    auditLogRequestBodyBody: AuditLogRequestBodyBody,
    params?: PostAuditLogParams,
 ) => {
      
      
      return customInstance<AuditLogResponse>(
      {url: `/internal/v3/admin/audit_log`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: auditLogRequestBodyBody,
        params
    },
      );
    }
  

export const getPostAuditLogQueryKey = (auditLogRequestBodyBody: AuditLogRequestBodyBody,
    params?: PostAuditLogParams,) => {
    return [`/internal/v3/admin/audit_log`, ...(params ? [params]: []), auditLogRequestBodyBody] as const;
    }

    
export const getPostAuditLogQueryOptions = <TData = Awaited<ReturnType<typeof postAuditLog>>, TError = Error>(auditLogRequestBodyBody: AuditLogRequestBodyBody,
    params?: PostAuditLogParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof postAuditLog>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPostAuditLogQueryKey(auditLogRequestBodyBody,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof postAuditLog>>> = () => postAuditLog(auditLogRequestBodyBody,params, );

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof postAuditLog>>, TError, TData> & { queryKey: QueryKey }
}

export type PostAuditLogQueryResult = NonNullable<Awaited<ReturnType<typeof postAuditLog>>>
export type PostAuditLogQueryError = Error

/**
 * @summary Get Audit Log
 */
export const usePostAuditLog = <TData = Awaited<ReturnType<typeof postAuditLog>>, TError = Error>(
 auditLogRequestBodyBody: AuditLogRequestBodyBody,
    params?: PostAuditLogParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof postAuditLog>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPostAuditLogQueryOptions(auditLogRequestBodyBody,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Get the filter values for the audit log.
 * @summary Get Audit Log Filter Values
 */
export const postAuditLogFilterValues = (
    auditLogRequestBodyBody: AuditLogRequestBodyBody,
    params: PostAuditLogFilterValuesParams,
 ) => {
      
      
      return customInstance<FilterValues>(
      {url: `/internal/v3/admin/audit_log/filter_values`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: auditLogRequestBodyBody,
        params
    },
      );
    }
  


export const getPostAuditLogFilterValuesMutationOptions = <TError = Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAuditLogFilterValues>>, TError,{data: AuditLogRequestBodyBody;params: PostAuditLogFilterValuesParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postAuditLogFilterValues>>, TError,{data: AuditLogRequestBodyBody;params: PostAuditLogFilterValuesParams}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAuditLogFilterValues>>, {data: AuditLogRequestBodyBody;params: PostAuditLogFilterValuesParams}> = (props) => {
          const {data,params} = props ?? {};

          return  postAuditLogFilterValues(data,params,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostAuditLogFilterValuesMutationResult = NonNullable<Awaited<ReturnType<typeof postAuditLogFilterValues>>>
    export type PostAuditLogFilterValuesMutationBody = AuditLogRequestBodyBody
    export type PostAuditLogFilterValuesMutationError = Error

    /**
 * @summary Get Audit Log Filter Values
 */
export const usePostAuditLogFilterValues = <TError = Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAuditLogFilterValues>>, TError,{data: AuditLogRequestBodyBody;params: PostAuditLogFilterValuesParams}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postAuditLogFilterValues>>,
        TError,
        {data: AuditLogRequestBodyBody;params: PostAuditLogFilterValuesParams},
        TContext
      > => {

      const mutationOptions = getPostAuditLogFilterValuesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * An *internal only* endpoint used for triggering Celery tasks by name.
This endpoint should never be adapted to take any input besides the
tasks's name.

 * @summary Trigger Celery task
 */
export const triggerCeleryJobSeriouslyInternalOnly = (
    taskName: string,
    inlineObjectBody: InlineObjectBody,
 ) => {
      
      
      return customInstance<void>(
      {url: `/internal/v3/admin/celery_task/${taskName}`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: inlineObjectBody
    },
      );
    }
  


export const getTriggerCeleryJobSeriouslyInternalOnlyMutationOptions = <TError = Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof triggerCeleryJobSeriouslyInternalOnly>>, TError,{taskName: string;data: InlineObjectBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof triggerCeleryJobSeriouslyInternalOnly>>, TError,{taskName: string;data: InlineObjectBody}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof triggerCeleryJobSeriouslyInternalOnly>>, {taskName: string;data: InlineObjectBody}> = (props) => {
          const {taskName,data} = props ?? {};

          return  triggerCeleryJobSeriouslyInternalOnly(taskName,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TriggerCeleryJobSeriouslyInternalOnlyMutationResult = NonNullable<Awaited<ReturnType<typeof triggerCeleryJobSeriouslyInternalOnly>>>
    export type TriggerCeleryJobSeriouslyInternalOnlyMutationBody = InlineObjectBody
    export type TriggerCeleryJobSeriouslyInternalOnlyMutationError = Error

    /**
 * @summary Trigger Celery task
 */
export const useTriggerCeleryJobSeriouslyInternalOnly = <TError = Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof triggerCeleryJobSeriouslyInternalOnly>>, TError,{taskName: string;data: InlineObjectBody}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof triggerCeleryJobSeriouslyInternalOnly>>,
        TError,
        {taskName: string;data: InlineObjectBody},
        TContext
      > => {

      const mutationOptions = getTriggerCeleryJobSeriouslyInternalOnlyMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Get a count of active Value Chain jobs.
 */
export const getCountOfActiveValueChainJobs = (
    
 signal?: AbortSignal
) => {
      
      
      return customInstance<ActiveValueChainJobsCount>(
      {url: `/internal/v3/admin/count_active_vc_jobs`, method: 'GET', signal
    },
      );
    }
  

export const getGetCountOfActiveValueChainJobsQueryKey = () => {
    return [`/internal/v3/admin/count_active_vc_jobs`] as const;
    }

    
export const getGetCountOfActiveValueChainJobsQueryOptions = <TData = Awaited<ReturnType<typeof getCountOfActiveValueChainJobs>>, TError = Error>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCountOfActiveValueChainJobs>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCountOfActiveValueChainJobsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCountOfActiveValueChainJobs>>> = ({ signal }) => getCountOfActiveValueChainJobs(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCountOfActiveValueChainJobs>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCountOfActiveValueChainJobsQueryResult = NonNullable<Awaited<ReturnType<typeof getCountOfActiveValueChainJobs>>>
export type GetCountOfActiveValueChainJobsQueryError = Error

/**
 * @summary Get a count of active Value Chain jobs.
 */
export const useGetCountOfActiveValueChainJobs = <TData = Awaited<ReturnType<typeof getCountOfActiveValueChainJobs>>, TError = Error>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCountOfActiveValueChainJobs>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCountOfActiveValueChainJobsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Get a mapping of country ISO2 codes to country names
 */
export const getCountryIso2Mapping = (
    
 signal?: AbortSignal
) => {
      
      
      return customInstance<GetCountryIso2Mapping200>(
      {url: `/internal/v3/admin/country_iso2_mapping`, method: 'GET', signal
    },
      );
    }
  

export const getGetCountryIso2MappingQueryKey = () => {
    return [`/internal/v3/admin/country_iso2_mapping`] as const;
    }

    
export const getGetCountryIso2MappingQueryOptions = <TData = Awaited<ReturnType<typeof getCountryIso2Mapping>>, TError = Error>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCountryIso2Mapping>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCountryIso2MappingQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCountryIso2Mapping>>> = ({ signal }) => getCountryIso2Mapping(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCountryIso2Mapping>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCountryIso2MappingQueryResult = NonNullable<Awaited<ReturnType<typeof getCountryIso2Mapping>>>
export type GetCountryIso2MappingQueryError = Error

/**
 * @summary Get a mapping of country ISO2 codes to country names
 */
export const useGetCountryIso2Mapping = <TData = Awaited<ReturnType<typeof getCountryIso2Mapping>>, TError = Error>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCountryIso2Mapping>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCountryIso2MappingQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary USE WITH EXTREME CAUTION. Delete all System of Record data on the entire spoke.
 */
export const deleteAllSorData = (
    params: DeleteAllSorDataParams,
 ) => {
      
      
      return customInstance<void>(
      {url: `/internal/v3/admin/delete_all_sor_data`, method: 'DELETE',
        params
    },
      );
    }
  


export const getDeleteAllSorDataMutationOptions = <TError = Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteAllSorData>>, TError,{params: DeleteAllSorDataParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteAllSorData>>, TError,{params: DeleteAllSorDataParams}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteAllSorData>>, {params: DeleteAllSorDataParams}> = (props) => {
          const {params} = props ?? {};

          return  deleteAllSorData(params,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteAllSorDataMutationResult = NonNullable<Awaited<ReturnType<typeof deleteAllSorData>>>
    
    export type DeleteAllSorDataMutationError = Error

    /**
 * @summary USE WITH EXTREME CAUTION. Delete all System of Record data on the entire spoke.
 */
export const useDeleteAllSorData = <TError = Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteAllSorData>>, TError,{params: DeleteAllSorDataParams}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof deleteAllSorData>>,
        TError,
        {params: DeleteAllSorDataParams},
        TContext
      > => {

      const mutationOptions = getDeleteAllSorDataMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Check if a user is authorized for a resource capability
 */
export const getResourceCapabilitiesUserIsAuthorizedFor = (
    isUserAuthorizedForResourceCapabilityRequestBodyBody: IsUserAuthorizedForResourceCapabilityRequestBodyBody,
 ) => {
      
      
      return customInstance<APIResourceCapabilities>(
      {url: `/internal/v3/admin/get_resource_capabilities_user_is_authorized_for`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: isUserAuthorizedForResourceCapabilityRequestBodyBody
    },
      );
    }
  

export const getGetResourceCapabilitiesUserIsAuthorizedForQueryKey = (isUserAuthorizedForResourceCapabilityRequestBodyBody: IsUserAuthorizedForResourceCapabilityRequestBodyBody,) => {
    return [`/internal/v3/admin/get_resource_capabilities_user_is_authorized_for`, isUserAuthorizedForResourceCapabilityRequestBodyBody] as const;
    }

    
export const getGetResourceCapabilitiesUserIsAuthorizedForQueryOptions = <TData = Awaited<ReturnType<typeof getResourceCapabilitiesUserIsAuthorizedFor>>, TError = Error>(isUserAuthorizedForResourceCapabilityRequestBodyBody: IsUserAuthorizedForResourceCapabilityRequestBodyBody, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getResourceCapabilitiesUserIsAuthorizedFor>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetResourceCapabilitiesUserIsAuthorizedForQueryKey(isUserAuthorizedForResourceCapabilityRequestBodyBody);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getResourceCapabilitiesUserIsAuthorizedFor>>> = () => getResourceCapabilitiesUserIsAuthorizedFor(isUserAuthorizedForResourceCapabilityRequestBodyBody, );

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getResourceCapabilitiesUserIsAuthorizedFor>>, TError, TData> & { queryKey: QueryKey }
}

export type GetResourceCapabilitiesUserIsAuthorizedForQueryResult = NonNullable<Awaited<ReturnType<typeof getResourceCapabilitiesUserIsAuthorizedFor>>>
export type GetResourceCapabilitiesUserIsAuthorizedForQueryError = Error

/**
 * @summary Check if a user is authorized for a resource capability
 */
export const useGetResourceCapabilitiesUserIsAuthorizedFor = <TData = Awaited<ReturnType<typeof getResourceCapabilitiesUserIsAuthorizedFor>>, TError = Error>(
 isUserAuthorizedForResourceCapabilityRequestBodyBody: IsUserAuthorizedForResourceCapabilityRequestBodyBody, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getResourceCapabilitiesUserIsAuthorizedFor>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetResourceCapabilitiesUserIsAuthorizedForQueryOptions(isUserAuthorizedForResourceCapabilityRequestBodyBody,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Get a status report for a value chain regeneration job.
 */
export const getRegenerateValueChainJobStatus = (
    jobId: string,
 signal?: AbortSignal
) => {
      
      
      return customInstance<JobStatusReport>(
      {url: `/internal/v3/admin/regenerate_value_chain/job_id/${jobId}`, method: 'GET', signal
    },
      );
    }
  

export const getGetRegenerateValueChainJobStatusQueryKey = (jobId: string,) => {
    return [`/internal/v3/admin/regenerate_value_chain/job_id/${jobId}`] as const;
    }

    
export const getGetRegenerateValueChainJobStatusQueryOptions = <TData = Awaited<ReturnType<typeof getRegenerateValueChainJobStatus>>, TError = Error>(jobId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getRegenerateValueChainJobStatus>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetRegenerateValueChainJobStatusQueryKey(jobId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getRegenerateValueChainJobStatus>>> = ({ signal }) => getRegenerateValueChainJobStatus(jobId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(jobId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getRegenerateValueChainJobStatus>>, TError, TData> & { queryKey: QueryKey }
}

export type GetRegenerateValueChainJobStatusQueryResult = NonNullable<Awaited<ReturnType<typeof getRegenerateValueChainJobStatus>>>
export type GetRegenerateValueChainJobStatusQueryError = Error

/**
 * @summary Get a status report for a value chain regeneration job.
 */
export const useGetRegenerateValueChainJobStatus = <TData = Awaited<ReturnType<typeof getRegenerateValueChainJobStatus>>, TError = Error>(
 jobId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getRegenerateValueChainJobStatus>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetRegenerateValueChainJobStatusQueryOptions(jobId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary List all Risk List Configs
 */
export const listRiskListConfigs = (
    
 signal?: AbortSignal
) => {
      
      
      return customInstance<RiskListConfigs>(
      {url: `/internal/v3/admin/risk_list_config`, method: 'GET', signal
    },
      );
    }
  

export const getListRiskListConfigsQueryKey = () => {
    return [`/internal/v3/admin/risk_list_config`] as const;
    }

    
export const getListRiskListConfigsQueryOptions = <TData = Awaited<ReturnType<typeof listRiskListConfigs>>, TError = Error>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listRiskListConfigs>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListRiskListConfigsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listRiskListConfigs>>> = ({ signal }) => listRiskListConfigs(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listRiskListConfigs>>, TError, TData> & { queryKey: QueryKey }
}

export type ListRiskListConfigsQueryResult = NonNullable<Awaited<ReturnType<typeof listRiskListConfigs>>>
export type ListRiskListConfigsQueryError = Error

/**
 * @summary List all Risk List Configs
 */
export const useListRiskListConfigs = <TData = Awaited<ReturnType<typeof listRiskListConfigs>>, TError = Error>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listRiskListConfigs>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListRiskListConfigsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Update Risk List Config
 */
export const updateRiskListConfigs = (
    updateRiskListConfigRequestBody: UpdateRiskListConfigRequestBody,
 ) => {
      
      
      return customInstance<void>(
      {url: `/internal/v3/admin/risk_list_config`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: updateRiskListConfigRequestBody
    },
      );
    }
  


export const getUpdateRiskListConfigsMutationOptions = <TError = Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateRiskListConfigs>>, TError,{data: UpdateRiskListConfigRequestBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof updateRiskListConfigs>>, TError,{data: UpdateRiskListConfigRequestBody}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateRiskListConfigs>>, {data: UpdateRiskListConfigRequestBody}> = (props) => {
          const {data} = props ?? {};

          return  updateRiskListConfigs(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateRiskListConfigsMutationResult = NonNullable<Awaited<ReturnType<typeof updateRiskListConfigs>>>
    export type UpdateRiskListConfigsMutationBody = UpdateRiskListConfigRequestBody
    export type UpdateRiskListConfigsMutationError = Error

    /**
 * @summary Update Risk List Config
 */
export const useUpdateRiskListConfigs = <TError = Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateRiskListConfigs>>, TError,{data: UpdateRiskListConfigRequestBody}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof updateRiskListConfigs>>,
        TError,
        {data: UpdateRiskListConfigRequestBody},
        TContext
      > => {

      const mutationOptions = getUpdateRiskListConfigsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get the roles and permissions associated with the API token used to auth this request.
 * @summary Get User Info for API token.
 */
export const getUserInfo = (
    
 signal?: AbortSignal
) => {
      
      
      return customInstance<GetUserInfo200>(
      {url: `/internal/v3/admin/user_info`, method: 'GET', signal
    },
      );
    }
  

export const getGetUserInfoQueryKey = () => {
    return [`/internal/v3/admin/user_info`] as const;
    }

    
export const getGetUserInfoQueryOptions = <TData = Awaited<ReturnType<typeof getUserInfo>>, TError = Error>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUserInfo>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserInfoQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserInfo>>> = ({ signal }) => getUserInfo(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUserInfo>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUserInfoQueryResult = NonNullable<Awaited<ReturnType<typeof getUserInfo>>>
export type GetUserInfoQueryError = Error

/**
 * @summary Get User Info for API token.
 */
export const useGetUserInfo = <TData = Awaited<ReturnType<typeof getUserInfo>>, TError = Error>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUserInfo>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUserInfoQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Get the arguments that are used when generating this Value Chain.
 * @summary Get the Value Chain Creation Arguments for the Supplied Value Chain ID.
 */
export const getValueChainCreationArgsByIdentifier = (
    getValueChainCreationArgumentsV3RequestBody: GetValueChainCreationArgumentsV3RequestBody,
 ) => {
      
      
      return customInstance<ValueChainCreationArgumentsV3>(
      {url: `/internal/v3/admin/vc_creation_args/get`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: getValueChainCreationArgumentsV3RequestBody
    },
      );
    }
  


export const getGetValueChainCreationArgsByIdentifierMutationOptions = <TError = Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getValueChainCreationArgsByIdentifier>>, TError,{data: GetValueChainCreationArgumentsV3RequestBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof getValueChainCreationArgsByIdentifier>>, TError,{data: GetValueChainCreationArgumentsV3RequestBody}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof getValueChainCreationArgsByIdentifier>>, {data: GetValueChainCreationArgumentsV3RequestBody}> = (props) => {
          const {data} = props ?? {};

          return  getValueChainCreationArgsByIdentifier(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type GetValueChainCreationArgsByIdentifierMutationResult = NonNullable<Awaited<ReturnType<typeof getValueChainCreationArgsByIdentifier>>>
    export type GetValueChainCreationArgsByIdentifierMutationBody = GetValueChainCreationArgumentsV3RequestBody
    export type GetValueChainCreationArgsByIdentifierMutationError = Error

    /**
 * @summary Get the Value Chain Creation Arguments for the Supplied Value Chain ID.
 */
export const useGetValueChainCreationArgsByIdentifier = <TError = Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getValueChainCreationArgsByIdentifier>>, TError,{data: GetValueChainCreationArgumentsV3RequestBody}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof getValueChainCreationArgsByIdentifier>>,
        TError,
        {data: GetValueChainCreationArgumentsV3RequestBody},
        TContext
      > => {

      const mutationOptions = getGetValueChainCreationArgsByIdentifierMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get the arguments that are used when generating the last version of the Value Chain. Use label to select between latest and pending.
 * @summary Get the historical Value Chain Creation Arguments for a supplied Value Chain ID.
 */
export const getValueChainHistoricalCreationArgs = (
    valueChainId: number,
    params?: GetValueChainHistoricalCreationArgsParams,
 signal?: AbortSignal
) => {
      
      
      return customInstance<ValueChainCreationArgumentsV3>(
      {url: `/internal/v3/admin/vc_creation_args/historical/${valueChainId}`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetValueChainHistoricalCreationArgsQueryKey = (valueChainId: number,
    params?: GetValueChainHistoricalCreationArgsParams,) => {
    return [`/internal/v3/admin/vc_creation_args/historical/${valueChainId}`, ...(params ? [params]: [])] as const;
    }

    
export const getGetValueChainHistoricalCreationArgsQueryOptions = <TData = Awaited<ReturnType<typeof getValueChainHistoricalCreationArgs>>, TError = Error>(valueChainId: number,
    params?: GetValueChainHistoricalCreationArgsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getValueChainHistoricalCreationArgs>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetValueChainHistoricalCreationArgsQueryKey(valueChainId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getValueChainHistoricalCreationArgs>>> = ({ signal }) => getValueChainHistoricalCreationArgs(valueChainId,params, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(valueChainId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getValueChainHistoricalCreationArgs>>, TError, TData> & { queryKey: QueryKey }
}

export type GetValueChainHistoricalCreationArgsQueryResult = NonNullable<Awaited<ReturnType<typeof getValueChainHistoricalCreationArgs>>>
export type GetValueChainHistoricalCreationArgsQueryError = Error

/**
 * @summary Get the historical Value Chain Creation Arguments for a supplied Value Chain ID.
 */
export const useGetValueChainHistoricalCreationArgs = <TData = Awaited<ReturnType<typeof getValueChainHistoricalCreationArgs>>, TError = Error>(
 valueChainId: number,
    params?: GetValueChainHistoricalCreationArgsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getValueChainHistoricalCreationArgs>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetValueChainHistoricalCreationArgsQueryOptions(valueChainId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Upsert the arguments that are used when generating this Value Chain.
 * @summary Update the Value Chain Creation Arguments for the Supplied Value Chain ID.
 */
export const upsertValueChainCreationArgsByIdentifier = (
    upsertValueChainCreationArgumentsV3RequestBody: UpsertValueChainCreationArgumentsV3RequestBody,
 ) => {
      
      
      return customInstance<ValueChainCreationArgumentsV3>(
      {url: `/internal/v3/admin/vc_creation_args/upsert`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: upsertValueChainCreationArgumentsV3RequestBody
    },
      );
    }
  


export const getUpsertValueChainCreationArgsByIdentifierMutationOptions = <TError = Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof upsertValueChainCreationArgsByIdentifier>>, TError,{data: UpsertValueChainCreationArgumentsV3RequestBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof upsertValueChainCreationArgsByIdentifier>>, TError,{data: UpsertValueChainCreationArgumentsV3RequestBody}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof upsertValueChainCreationArgsByIdentifier>>, {data: UpsertValueChainCreationArgumentsV3RequestBody}> = (props) => {
          const {data} = props ?? {};

          return  upsertValueChainCreationArgsByIdentifier(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpsertValueChainCreationArgsByIdentifierMutationResult = NonNullable<Awaited<ReturnType<typeof upsertValueChainCreationArgsByIdentifier>>>
    export type UpsertValueChainCreationArgsByIdentifierMutationBody = UpsertValueChainCreationArgumentsV3RequestBody
    export type UpsertValueChainCreationArgsByIdentifierMutationError = Error

    /**
 * @summary Update the Value Chain Creation Arguments for the Supplied Value Chain ID.
 */
export const useUpsertValueChainCreationArgsByIdentifier = <TError = Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof upsertValueChainCreationArgsByIdentifier>>, TError,{data: UpsertValueChainCreationArgumentsV3RequestBody}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof upsertValueChainCreationArgsByIdentifier>>,
        TError,
        {data: UpsertValueChainCreationArgumentsV3RequestBody},
        TContext
      > => {

      const mutationOptions = getUpsertValueChainCreationArgsByIdentifierMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Use authorization decorator to check if role of user is admin role
 * @summary Verify if the current user is admin.
 */
export const verifyIsAdmin = (
    
 signal?: AbortSignal
) => {
      
      
      return customInstance<unknown>(
      {url: `/internal/v3/admin/verify`, method: 'GET', signal
    },
      );
    }
  

export const getVerifyIsAdminQueryKey = () => {
    return [`/internal/v3/admin/verify`] as const;
    }

    
export const getVerifyIsAdminQueryOptions = <TData = Awaited<ReturnType<typeof verifyIsAdmin>>, TError = Error>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof verifyIsAdmin>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getVerifyIsAdminQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof verifyIsAdmin>>> = ({ signal }) => verifyIsAdmin(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof verifyIsAdmin>>, TError, TData> & { queryKey: QueryKey }
}

export type VerifyIsAdminQueryResult = NonNullable<Awaited<ReturnType<typeof verifyIsAdmin>>>
export type VerifyIsAdminQueryError = Error

/**
 * @summary Verify if the current user is admin.
 */
export const useVerifyIsAdmin = <TData = Awaited<ReturnType<typeof verifyIsAdmin>>, TError = Error>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof verifyIsAdmin>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getVerifyIsAdminQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Get a list of all Suppliers that are actively processing in Supplier Matching
 */
export const listOnlyProcessingSuppliers = (
    
 signal?: AbortSignal
) => {
      
      
      return customInstance<ProcessingSuppliers>(
      {url: `/internal/v3/processing_suppliers`, method: 'GET', signal
    },
      );
    }
  

export const getListOnlyProcessingSuppliersQueryKey = () => {
    return [`/internal/v3/processing_suppliers`] as const;
    }

    
export const getListOnlyProcessingSuppliersQueryOptions = <TData = Awaited<ReturnType<typeof listOnlyProcessingSuppliers>>, TError = Error>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listOnlyProcessingSuppliers>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListOnlyProcessingSuppliersQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listOnlyProcessingSuppliers>>> = ({ signal }) => listOnlyProcessingSuppliers(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listOnlyProcessingSuppliers>>, TError, TData> & { queryKey: QueryKey }
}

export type ListOnlyProcessingSuppliersQueryResult = NonNullable<Awaited<ReturnType<typeof listOnlyProcessingSuppliers>>>
export type ListOnlyProcessingSuppliersQueryError = Error

/**
 * @summary Get a list of all Suppliers that are actively processing in Supplier Matching
 */
export const useListOnlyProcessingSuppliers = <TData = Awaited<ReturnType<typeof listOnlyProcessingSuppliers>>, TError = Error>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listOnlyProcessingSuppliers>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListOnlyProcessingSuppliersQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Get the Value Chain Jobs which started after the given start_time. If no start_time is given, jobs from the last day will be returned.
 * @summary Get a list of all Value Chain jobs.
 */
export const listValueChainJobs = (
    params?: ListValueChainJobsParams,
 signal?: AbortSignal
) => {
      
      
      return customInstance<ValueChainJobs>(
      {url: `/internal/v3/value_chains/jobs`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getListValueChainJobsQueryKey = (params?: ListValueChainJobsParams,) => {
    return [`/internal/v3/value_chains/jobs`, ...(params ? [params]: [])] as const;
    }

    
export const getListValueChainJobsQueryOptions = <TData = Awaited<ReturnType<typeof listValueChainJobs>>, TError = Error>(params?: ListValueChainJobsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listValueChainJobs>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListValueChainJobsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listValueChainJobs>>> = ({ signal }) => listValueChainJobs(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listValueChainJobs>>, TError, TData> & { queryKey: QueryKey }
}

export type ListValueChainJobsQueryResult = NonNullable<Awaited<ReturnType<typeof listValueChainJobs>>>
export type ListValueChainJobsQueryError = Error

/**
 * @summary Get a list of all Value Chain jobs.
 */
export const useListValueChainJobs = <TData = Awaited<ReturnType<typeof listValueChainJobs>>, TError = Error>(
 params?: ListValueChainJobsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listValueChainJobs>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListValueChainJobsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



