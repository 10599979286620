import { ButtonHTMLAttributes, SyntheticEvent, forwardRef } from 'react'
import { Slot } from '@radix-ui/react-slot'
import { buttonStyles } from './Button.css'
import { RecipeVariants } from '@vanilla-extract/recipes'
import clsx from 'clsx'

export enum ButtonVariant {
  primary = 'primary',
  secondary = 'secondary',
  tertiary = 'tertiary',
  ghost = 'ghost',
  link = 'link',
  danger = 'danger',
}

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
  RecipeVariants<typeof buttonStyles> & {
    asChild?: boolean
    'data-testid'?: string
  }

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      asChild,
      children,
      className,
      disabled = false,
      'aria-disabled': ariaDisabled,
      onClick,
      onKeyUp,
      size,
      variant,
      icon,
      pill,
      type,
      ...otherProps
    },
    ref,
  ) => {
    const handleDisabledEvent = (event: SyntheticEvent) => event.preventDefault()
    const Comp = asChild ? Slot : 'button'
    return (
      <Comp
        className={clsx(buttonStyles({ variant, size, icon, pill }), className)}
        disabled={disabled}
        onClick={disabled ? handleDisabledEvent : onClick}
        onKeyUp={disabled ? handleDisabledEvent : onKeyUp}
        aria-disabled={!!disabled || !!ariaDisabled}
        type={type ?? 'button'}
        ref={ref}
        {...otherProps}
      >
        {children}
      </Comp>
    )
  },
)
