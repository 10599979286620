'use client'

import { TooltipDefaultStyle } from './Tooltip.css'
import type { Portal, TooltipProviderProps } from '@radix-ui/react-tooltip'
import * as TooltipPrimitive from '@radix-ui/react-tooltip'

import { ALIGN_OPTIONS, SIDE_OPTIONS } from '@radix-ui/react-popper'
import { ComponentProps, ReactNode, forwardRef } from 'react'
import clsx from 'clsx'

interface TooltipProps extends TooltipProviderProps {
  container?: ComponentProps<typeof Portal>['container']
  placement?: (typeof SIDE_OPTIONS)[number]
  align?: (typeof ALIGN_OPTIONS)[number]
  delayed?: boolean
  label?: ReactNode | string
  children: ReactNode
  hasArrow?: boolean
  sideOffset?: number
  disableHoverableContent?: boolean
  className?: string
  noPortal?: boolean
}

export const LegacyTooltip = ({
  container,
  placement = 'top',
  delayed = true,
  children,
  label,
  align,
  hasArrow = false,
  sideOffset = 0,
  disableHoverableContent = false,
  className,
  noPortal = false,
}: TooltipProps) => {
  if (!label) return <>{children}</>

  const content = (
    <TooltipPrimitive.TooltipContent
      className={clsx(TooltipDefaultStyle, className)}
      side={placement}
      align={align}
      sideOffset={sideOffset}
    >
      {label}
      {hasArrow && <TooltipPrimitive.Arrow />}
    </TooltipPrimitive.TooltipContent>
  )

  return (
    <TooltipPrimitive.TooltipProvider
      delayDuration={delayed ? 500 : 0}
      disableHoverableContent={disableHoverableContent}
    >
      <TooltipPrimitive.Root>
        <TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>
        {noPortal ? <>{content}</> : <TooltipPrimitive.Portal container={container}>{content}</TooltipPrimitive.Portal>}
      </TooltipPrimitive.Root>
    </TooltipPrimitive.TooltipProvider>
  )
}

const TooltipProvider = TooltipPrimitive.Provider

const Tooltip = TooltipPrimitive.Root

const TooltipTrigger = TooltipPrimitive.Trigger

const TooltipArrow = TooltipPrimitive.Arrow

const TooltipPortal = TooltipPrimitive.Portal

const TooltipContent = forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, sideOffset = 4, ...props }, ref) => (
  <TooltipPrimitive.Content
    ref={ref}
    sideOffset={sideOffset}
    className={clsx(TooltipDefaultStyle, className)}
    {...props}
  />
))
TooltipContent.displayName = TooltipPrimitive.Content.displayName

export { Tooltip, TooltipTrigger, TooltipArrow, TooltipPortal, TooltipContent, TooltipProvider }
