/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.0.0
 */

export type ProcurementSearchRequestV2Collection =
  (typeof ProcurementSearchRequestV2Collection)[keyof typeof ProcurementSearchRequestV2Collection]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProcurementSearchRequestV2Collection = {
  procurement: 'procurement',
} as const
