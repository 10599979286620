import { datadogRum } from '@datadog/browser-rum'
import env from '@beam-australia/react-env'
const isReportingEnabled = Boolean(env('DATADOG_RUM_APPLICATION_ID'))

// TODO: Explictly type host as an enum
export const logDebug = (message?: unknown, host?: string, ...optionalParams: unknown[]) => {
  const isPreProd = host?.includes('stage') || host?.includes('demo')
  if (process.env.NODE_ENV === 'development' || isPreProd) {
    console.log(message, ...optionalParams) // eslint-disable-line no-console
  }
}

export const logError = (error: unknown) => {
  if (isReportingEnabled && error) {
    datadogRum.addError(error)
  } else {
    console.error(error) // eslint-disable-line no-console
  }
}
