import { useUserStore } from '@stores/userStore'
import { getAccessToken, isAuthenticated } from '@utils/auth0'
import axios, { isAxiosError, AxiosRequestConfig } from 'axios'
import { logDebug } from '@utils/logger'
import { navigateToLogout } from '@hooks/useLogout'
import { isFeatureEnabled } from '@components/Feature'

export const LEGACY_AUTH_HEADER_NAME = 'X-Api-Key'
export const AUTH0_AUTH_HEADER_NAME = 'Authorization'

export type Request = AxiosRequestConfig & {
  resource: string
}

export const authenticatedFetcher = isFeatureEnabled('keycloak_auth')
  ? async <T>(config: Request): Promise<T> => {
      const currentUrl = new URL(window.location.href)
      const params = new URLSearchParams(window.location.search)
      const hash = currentUrl.hash
      try {
        const result = await axios(config.resource, {
          baseURL: '/',
          ...(config ?? {}),
          headers: {
            ...(config.headers ?? {}),
          },
        })
        return result?.data
      } catch (error) {
        if (isAxiosError(error) && error.response?.status === 401) {
          let redirectUrl = currentUrl.pathname
          if (params.size > 0) {
            redirectUrl += `?${params.toString()}`
          }
          if (hash && hash.length > 0) {
            redirectUrl += hash
          }
          window.location.assign(`/login?rd=${encodeURIComponent(redirectUrl)}`)
        }
        throw error
      }
    }
  : async <T>(config: Request): Promise<T> => {
      // TODO - (LEGACY_AUTH) - The should use Auth0 and apiKey value (and related checks) can get removed when we remove legacy auth.
      const { apiKey } = useUserStore.getState()
      const shouldUseAuth0 = !apiKey
      const authenticatedWithAuth0 = shouldUseAuth0 && (await isAuthenticated())

      // If the user isn't logged in and we're trying to make authenticated requests, we should bail out
      if ((shouldUseAuth0 && !authenticatedWithAuth0) || (!shouldUseAuth0 && !apiKey)) {
        navigateToLogout({ redirectPath: window.location.href })
      }

      const authToken = shouldUseAuth0 ? await getAccessToken() : apiKey
      const authHeaderName = shouldUseAuth0 ? AUTH0_AUTH_HEADER_NAME : LEGACY_AUTH_HEADER_NAME

      try {
        const result = await axios(config.resource, {
          baseURL: '/',
          ...(config ?? {}),
          headers: {
            // Merge the headers rather than clobbering the auth header if config has additional headers defined
            ...(config.headers ?? {}),
            [authHeaderName]: shouldUseAuth0 ? `Bearer ${authToken}` : authToken,
          },
        })
        return result?.data
      } catch (error) {
        if (isAxiosError(error) && error.response?.status === 401) {
          navigateToLogout({ redirectPath: window.location.href })
        }
        throw error
      }
    }

/**  LEGACY: Only used to validate incoming API keys via legacy auth */
export const fetcher = isFeatureEnabled('keycloak_auth')
  ? (_authToken: string, _authHeaderName: string) =>
      <T>(config: Request): Promise<T> =>
        axios(config.resource, {
          baseURL: '/',
          ...(config ?? {}),
        }).then(res => res?.data)
  : (authToken: string, authHeaderName: string) =>
      authToken
        ? <T>(config: Request): Promise<T> =>
            axios(config.resource, {
              baseURL: '/',
              headers: {
                [authHeaderName]: authToken,
              },
              ...(config ?? {}),
            }).then(res => res?.data)
        : <T>(): Promise<T> =>
            new Promise((_, reject) => {
              logDebug('api key not loaded', window.location.host)
              reject('api key not loaded')
            })
